export default {
    namespace: 'formEditor',
    initialState: {
        mstId: '',//数据masterId
        formId: "",
        formType: "",//表单类型 Normal,Process,Document,DocumentForm
        data: null,//设计器下的所有节点
        properties: null,//表单属性
        fields: [],//表单所有数据库字段
        values: { data: {}, },//整个表单的值对象

        dataLink: { data: {} },//数据关联
        rangeDataLink: { data: {} },//数据范围关联
        linkForms: [],//数据关联--当前应用下所有的表单
        queryData: {},//关联查询结果

        metaDataGroups: [],//
        metaDatas: []

    },
    setUnmount: () => {
        return {
            mstId: '',//数据masterId
            formId: "",
            formType: "",//表单类型 Normal,Process,Document,DocumentForm
            data: null,//设计器下的所有节点
            properties: null,//表单属性
            fields: [],//表单所有数据库字段
            values: { data: {}, },//整个表单的值对象

            dataLink: { data: {} },//数据关联
            rangeDataLink: { data: {} },//数据范围关联
            linkForms: [],//数据关联--当前应用下所有的表单
            queryData: {},//关联查询结果

            metaDataGroups: [],//
            metaDatas: []
        }
    },
    initState: (s, _) => {
        return s
    },
    initValues: () => {
        return { values: { data: {} } }
    },
    setProperties: (property, state) => {
        let { properties } = state;
        properties = { ...properties, ...property };
        return { properties };
    },
    setValues: (value, state) => {
        let { values } = state;
        values = { ...values, ...value };
        return { values };
    },
    setDataLink: (data, state) => {
        let { dataLink } = state;
        dataLink = { ...dataLink, ...data };
        return { dataLink };
    },
    setRangeDataLink: (data, state) => {
        let { rangeDataLink } = state;
        rangeDataLink = { ...rangeDataLink, ...data };
        return { rangeDataLink };
    },
    setQueryData: (q, state) => {
        let { queryData } = state;
        queryData = { ...queryData, ...q };
        return { queryData };
    }
}