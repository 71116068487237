import { sxAjax } from "@/commons/ajax";
import { stringify } from "qs";
declare var abp: any;
export default {
    namespace: "listDesign",
    initialState: {
        InitialDisplay: true, //默认显示空
        searchList: [], //列表设计-搜索选中标签
        TableAllColumn: [], //列表所有字段,
        TableOperationColumn: {}, //列表操作列
        Showstable: [], //列表设计-表格选中标签,
        checkColumns: [],
        imageListData: [], //列表表格数据源
        imageListDataCount: 0, //列表数据总数
        searchFillInData: {
            //搜索条件值
            FormId: "",
            Filters: [],
        },
        tableCheck: [], //列表设计-表格选中标签
        selectedRows: [], //表格选中行数据
        currentRow: {}, //当前行
        StatusField: "", //档案状态字段名
        SourcesData: "", //数据来源字段名
        FileTypeField: "", //档案类型字段名
        NumberField: "", //编号字段名
        SortNumberField: "", //排序序号字段名
        CompanyNameField: "", //公司名称字段名
        YearField: "", //所属年度字段名
        AccountingPeriodField: "", //会计期间字段名
        VolumeTableID: "", //册表MstID字段名
        formId: "",
        VolumeFormID: "",
        BookFormID: "",
        MstId: "",
        FileType: "", //档案类型
        fields: [], //表单所有数据库字段
        loading: true,
        PositionField: "", //位置名
        PositionKeysField: "", //位置keys
        BasicConfigData: [], //基础配置
        MobileOperation: false, //是否有移动操作
        //YearField:'',
        //MonthField:''
        FileArrayField: "", //购物车的数据
        AuditStatusField: "", //审核状态
        BorrowerField: "", //借阅人
        BorrowerIdField: "",//借阅人编码
        ArchivesField: "", //档案室
        LendStatusField: "", //借出状态
        Category: "", //类目
        isChange: false, //列表设计是否有改变
        InitialTab: true, //表格属性,默认不显示
        SecurityField: "", //密级
        DocTypeField: "", //文件类型
        BorrowTypeField: "", //借阅类型
        CurrentPage: {}, //当前页
        ReturnStatusField: "", //归还状态
        UserNameField: "", //提交人
        SubmitTimeField: "", //提交时间
        VolumeData: [], //匹配册表数据
        MappingStatusField: "", //匹配状态
        MstIdsField: "", //匹配成功的mstId
        BusinessTypeField: "", //业务类型
        BusinessDocNoField: "", //业务单据号
        AppendicesField: "", //附件数
        CategoryField: "", //类目
        RetentionPeriodField: "", //保管年限
        PutawayPeopleField: "", //上架人
        PutawayTimeField: "", //上架时间
        SubmitterField: "", //提交人 册表
        SubmitterDateField: "", //提交时间 册表
        previewRow: {}, // 点击预览所在行数据
        previewLoading: false,
        selectedRowKeys: [], //选择的key
        Accessory: "",
        newGuid: '',//guid
    },
    /**组件卸载 */
    setUnmount: () => {
        return {
            InitialDisplay: true, //默认显示空
            searchList: [], //列表设计-搜索选中标签
            TableAllColumn: [], //列表所有字段,
            TableOperationColumn: {}, //列表操作列
            Showstable: [], //列表设计-表格选中标签,
            checkColumns: [],
            imageListData: [], //列表表格数据源
            imageListDataCount: 0, //列表数据总数
            searchFillInData: {
                //搜索条件值
                FormId: "",
                Filters: [],
            },
            selectedRows: [], //表格选中行数据
            currentRow: {}, //当前行
            StatusField: "", //档案状态字段名
            SourcesData: "", //数据来源字段名
            FileTypeField: "", //档案类型字段名
            NumberField: "", //编号字段名
            SortNumberField: "", //排序序号字段名
            CompanyNameField: "", //公司名称字段名
            YearField: "", //所属年度字段名
            AccountingPeriodField: "", //会计期间字段名
            VolumeTableID: "", //册表MstID字段名
            formId: "",
            VolumeFormID: "",
            BookFormID: "",
            MstId: "",
            FileType: "", //档案类型
            fields: [], //表单所有数据库字段
            loading: true,
            PositionField: "", //位置名
            PositionKeysField: "", //位置keys
            BasicConfigData: [], //基础配置
            MobileOperation: false, //是否有移动操作
            //YearField:'',
            //MonthField:''
            FileArrayField: "", //购物车的数据
            AuditStatusField: "", //审核状态
            BorrowerField: "", //借阅人
            BorrowerIdField: "",//借阅人编码
            ArchivesField: "", //档案室
            LendStatusField: "", //借出状态
            Category: "", //类目
            isChange: false, //列表设计是否有改变
            InitialTab: true, //表格属性,默认不显示
            DocTypeField: "",
            CurrentPage: {}, //当前页
            ReturnStatusField: "", //
            UserNameField: "", //提交人
            SubmitTimeField: "", //提交时间
            VolumeData: [], //匹配册表数据
            MappingStatusField: "", //匹配状态
            MstIdsField: "", //匹配成功的mstId
            BusinessTypeField: "", //业务类型
            BusinessDocNoField: "", //业务单据号
            AppendicesField: "", //附件数
            CategoryField: "", //类目
            RetentionPeriodField: "", //保管年限
            PutawayPeopleField: "", //上架人
            PutawayTimeField: "", //上架时间
            Accessory: "", //附件
            SubmitterField: "", //提交人 册表
            SubmitterDateField: "", //提交时间 册表
            // previewRow: {},
            selectedRowKeys: [], //选择的key
            newGuid: '',//guid
        };
    },
    setTableAllColumn: (TableAllColumn, _) => {
        return { TableAllColumn };
    },
    setPreviewLoading: (previewLoading, _) => {
        return { previewLoading };
    },
    setsearchFillInData: (searchFillInData, _) => {
        return { searchFillInData };
    },
    setPreviewRow: (previewRow, _) => {
        return { previewRow };
    },
    setInitialDisplay: (InitialDisplay, _) => {
        return { InitialDisplay };
    },
    setSelectedRows: (selectedRows, _) => {
        return { selectedRows };
    },
    setCurrentRow: (currentRow, _) => {
        return { currentRow };
    },
    setMstId: (MstId, _) => {
        return { MstId };
    },
    setFileType: (FileType, _) => {
        return { FileType };
    },
    setformId: (formId, _) => {
        return { formId };
    },
    setVolumeFormID: (VolumeFormID, _) => {
        return { VolumeFormID };
    },
    setBookFormID: (BookFormID, _) => {
        return { BookFormID };
    },
    setimageListData: (imageListData, _) => {
        return { imageListData };
    },
    setShowstable: (Showstable, _) => {
        return { Showstable };
    },
    setCheckColumns: (checkColumns, _) => {
        return { checkColumns };
    },
    setTableOperationColumn: (TableOperationColumn, _) => {
        return { TableOperationColumn };
    },
    setBasicConfigData: (BasicConfigData, _) => {
        return { BasicConfigData };
    },
    setMobileOperation: (MobileOperation, _) => {
        return { MobileOperation };
    },
    setCategory: (Category, _) => {
        return { Category };
    },
    setIsChange: (isChange, _) => {
        return { isChange };
    },
    setInitialTab: (InitialTab, _) => {
        return { InitialTab };
    },
    setCurrentPage: (CurrentPage, _) => {
        return { CurrentPage };
    },
    setAccessory: (Accessory, _) => {
        return { Accessory };
    },
    setSelectedRowKeys: (selectedRowKeys, _) => {
        return { selectedRowKeys };
    },
    setGuid: (newGuid, _) => {
        return { newGuid };
    },
    getForm: {
        payload: (params) => {
            return sxAjax.get(
                "api/services/app/AppObject/GetForm?formId=" + params.formId
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const {
                    formId,
                    formName,
                    formType,
                    data,
                    properties,
                    fields,
                    listDesignAttrs,
                } = payload.result;
                let Showstable = [];
                let TableAllColumn = [];
                let StatusField = payload.result.fields.find(function (value) {
                    //获取状态字段名
                    return value.customType === "Status";
                });
                let SourcesData = payload.result.fields.find(function (value) {
                    //获取数据来源字段名
                    return value.customType === "SourcesData";
                });
                let FileTypeField = payload.result.fields.find(function (
                    value
                ) {
                    //获取档案类型字段名
                    return value.customType === "FileType";
                });
                let VolumeTableID = payload.result.fields.find(function (
                    value
                ) {
                    //获取册表MstID字段名
                    return value.customType === "VolumeTableID";
                });
                let PositionField = payload.result.fields.find(function (
                    value
                ) {
                    //获取
                    return value.customType === "Position";
                });
                let PositionKeysField = payload.result.fields.find(function (
                    value
                ) {
                    //获取
                    return value.customType === "PositionKeys";
                });
                let FileArrayField = payload.result.fields.find(function (
                    value
                ) {
                    //获取
                    return value.customType === "FileArray";
                });
                let AuditStatusField = payload.result.fields.find(function (
                    value
                ) {
                    //获取
                    return value.customType === "AuditStatus";
                });
                let BorrowerField = payload.result.fields.find(function (
                    value
                ) {
                    //获取
                    return value.customType === "Borrower";
                });
                let BorrowerIdField = payload.result.fields.find(function (value) {
                    return value.customType === "BorrowerId";
                })
                let ArchivesField = payload.result.fields.find(function (
                    value
                ) {
                    //获取
                    return value.customType === "Archives";
                }); //
                let LendStatusField = payload.result.fields.find(function (
                    value
                ) {
                    //获取
                    return value.customType === "LendStatus";
                });
                let Number = payload.result.fields.find(function (value) {
                    //获取编号字段名（凭证号）
                    return value.customType === "number";
                });
                let SortNumber = payload.result.fields.find(function (value) {
                    //排序序号字段
                    return value.customType === "SortNumber";
                });
                let CompanyName = payload.result.fields.find(function (value) {
                    //公司名称字段
                    return value.customType === "CompanyName";
                });
                let Year = payload.result.fields.find(function (value) {
                    //所属年度
                    return value.customType === "Year";
                });
                let AccountingPeriod = payload.result.fields.find(function (
                    value
                ) {
                    //会计期间
                    return value.customType === "AccountingPeriod";
                });
                let SecurityField = payload.result.fields.find(function (
                    value
                ) {
                    //密级
                    return value.customType === "SecurityClassification";
                });
                let DocTypeField = payload.result.fields.find(function (value) {
                    //文件类型
                    return value.customType === "DocType";
                });
                let BorrowTypeField = payload.result.fields.find(function (
                    value
                ) {
                    //密级
                    return value.customType === "BorrowType";
                });
                let ReturnStatusField = payload.result.fields.find(function (
                    value
                ) {
                    //密级
                    return value.customType === "ReturnStatus";
                });
                let UserNameField = payload.result.fields.find(function (
                    value
                ) {
                    //提交人
                    return value.customType === "UserName";
                });

                let SubmitTimeField = payload.result.fields.find(function (
                    value
                ) {
                    //提交时间
                    return value.customType === "SubmitTime";
                });
                let SubmitterField = payload.result.fields.find(function (
                    value
                ) {
                    //提交人  册表
                    return value.customType === "Submitter";
                });

                let SubmitterDateField = payload.result.fields.find(function (
                    value
                ) {
                    //提交时间 册表
                    return value.customType === "SubmitterDate";
                });

                let MappingStatusField = payload.result.fields.find(function (
                    value
                ) {
                    //匹配状态
                    return value.customType === "MappingStatus";
                });
                let MstIdsField = payload.result.fields.find(function (value) {
                    //匹配成功的mstId
                    return value.customType === "MstIds";
                });
                let BusinessTypeField = payload.result.fields.find(function (
                    value
                ) {
                    //业务类型
                    return value.customType === "BusinessType";
                });
                let BusinessDocNoField = payload.result.fields.find(function (
                    value
                ) {
                    //业务单据号
                    return value.customType === "BusinessDocNo";
                });
                let AppendicesField = payload.result.fields.find(function (
                    value
                ) {
                    //附件数
                    return value.customType === "Appendices";
                });
                //Category
                let CategoryField = payload.result.fields.find(function (
                    value
                ) {
                    //类目
                    return value.customType === "Category";
                });
                //RetentionPeriod
                let RetentionPeriodField = payload.result.fields.find(function (
                    value
                ) {
                    //保管年限
                    return value.customType === "RetentionPeriod";
                });

                let PutawayPeopleField = payload.result.fields.find(function (
                    value
                ) {
                    //上架人
                    return value.customType === "PutawayPeople";
                });
                let PutawayTimeField = payload.result.fields.find(function (
                    value
                ) {
                    //上架时间
                    return value.customType === "PutawayTime";
                });
                if (listDesignAttrs != null) {
                    if (listDesignAttrs.tableChecks != null) {
                        for (
                            let i = 0;
                            i < listDesignAttrs.tableChecks.length;
                            i++
                        ) {
                            listDesignAttrs.tableChecks[
                                i
                            ].dataIndex = listDesignAttrs.tableChecks[
                                i
                            ].dataIndex.replace("-", "_");
                            listDesignAttrs.tableChecks[
                                i
                            ].key = listDesignAttrs.tableChecks[i].key.replace(
                                "-",
                                "_"
                            );
                            if (
                                listDesignAttrs.tableChecks[i].checkeds == true
                            ) {
                                switch (state.FileType) {
                                    case "原始凭证":
                                        //其他类型不展示的隐藏
                                        if (
                                            state.CurrentPage.FormName ===
                                            "册表"
                                        ) {
                                            if (
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "档案类型" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "案卷号" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "业务类型" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "业务单号" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "凭证是否生成" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "凭证数" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "标题" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "公司名称" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "所属年度" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "会计期间" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title === "状态"
                                            ) {
                                                Showstable.push(
                                                    listDesignAttrs.tableChecks[
                                                    i
                                                    ]
                                                );
                                                TableAllColumn.push(
                                                    listDesignAttrs.tableChecks[
                                                    i
                                                    ]
                                                );
                                                switch (
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title
                                                ) {
                                                    case "编号":
                                                        listDesignAttrs.tableChecks[
                                                            i
                                                        ].title = "凭证号";
                                                        break;
                                                    case "凭证数":
                                                        listDesignAttrs.tableChecks[
                                                            i
                                                        ].title = "文件数量";
                                                        break;
                                                }
                                            }
                                        } else {
                                            if (
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "业务类型" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "业务单号" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "凭证是否生成" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "数据来源" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "编号" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "附件数" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "匹配状态" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "提交人" ||
                                                listDesignAttrs.tableChecks[i]
                                                    .title == "提交时间"
                                            ) {
                                                Showstable.push(
                                                    listDesignAttrs.tableChecks[
                                                    i
                                                    ]
                                                );
                                                TableAllColumn.push(
                                                    listDesignAttrs.tableChecks[
                                                    i
                                                    ]
                                                );
                                                switch (
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title
                                                ) {
                                                    case "编号":
                                                        listDesignAttrs.tableChecks[
                                                            i
                                                        ].title = "凭证号";
                                                        break;
                                                    case "附件数":
                                                        listDesignAttrs.tableChecks[
                                                            i
                                                        ].title = "文件数量";
                                                        break;
                                                    case "提交人":
                                                        listDesignAttrs.tableChecks[
                                                            i
                                                        ].title = "创建人";
                                                        break;
                                                    case "提交时间":
                                                        listDesignAttrs.tableChecks[
                                                            i
                                                        ].title = "创建时间";
                                                        break;
                                                }
                                            }
                                        }

                                        break;
                                    case "记账凭证":
                                        //其他类型不展示的隐藏
                                        if (
                                            listDesignAttrs.tableChecks[i]
                                                .title != "编制日期" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "开始时间" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "结束时间" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "账簿页数" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "负责人" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "位置" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "档案号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "案卷号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务类型" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务单号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证是否生成" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "匹配状态"
                                        ) {
                                            switch (
                                            state.CurrentPage.fileStatus
                                            ) {
                                                case "档案装册":
                                                case "档案归档":
                                                    if (
                                                        state.CurrentPage
                                                            .FormName == "册表"
                                                    ) {
                                                        if (
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架人" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架时间"
                                                        ) {
                                                            Showstable.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                            TableAllColumn.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                        }
                                                    } else {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                                default:
                                                    if (
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title !=
                                                        "提交人" &&
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title != "提交时间"
                                                    ) {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                            }
                                        }
                                        switch (
                                        listDesignAttrs.tableChecks[i].title
                                        ) {
                                            case "编号":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "凭证号";
                                                break;
                                            case "档案类型":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "凭证类型";
                                                break;
                                            case "凭证号最小值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "凭证起始号";
                                                break;
                                            case "凭证号最大值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "凭证截止号";
                                                break;
                                        }
                                        break;
                                    case "总账":
                                    case "银行日记账":
                                    case "现金日记账":
                                    case "明细账":
                                        //其他类型不展示的隐藏
                                        if (
                                            listDesignAttrs.tableChecks[i]
                                                .title != "编制日期" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "过账状态" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "记账人" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "附件数" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "位置" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "档案号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "案卷号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务类型" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务单号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证是否生成" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "匹配状态"
                                        ) {
                                            switch (
                                            state.CurrentPage.fileStatus
                                            ) {
                                                case "档案装册":
                                                case "档案归档":
                                                    if (
                                                        state.CurrentPage
                                                            .FormName == "册表"
                                                    ) {
                                                        if (
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架人" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架时间"
                                                        ) {
                                                            Showstable.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                            TableAllColumn.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                        }
                                                    } else {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                                default:
                                                    if (
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title !=
                                                        "提交人" &&
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title != "提交时间"
                                                    ) {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                            }
                                        }
                                        switch (
                                        listDesignAttrs.tableChecks[i].title
                                        ) {
                                            case "档案类型":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "账簿类型";
                                                break;
                                            case "凭证日期":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "账簿日期";
                                                break;
                                            case "凭证数":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "账簿数";
                                                break;
                                            case "凭证号最小值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "账簿起始号";
                                                break;
                                            case "凭证号最大值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "账簿截止号";
                                                break;
                                        }
                                        break;
                                    case "EBS月度报表":
                                    case "EBS季度报表":
                                    case "EBS年度报表":
                                    case "合并报表":
                                        //其他类型不展示的隐藏
                                        if (
                                            listDesignAttrs.tableChecks[i]
                                                .title != "过账状态" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "账簿页数" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "记账人" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "位置" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "档案号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "案卷号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务类型" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务单号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证是否生成" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "匹配状态"
                                        ) {
                                            switch (
                                            state.CurrentPage.fileStatus
                                            ) {
                                                case "档案装册":
                                                case "档案归档":
                                                    if (
                                                        state.CurrentPage
                                                            .FormName == "册表"
                                                    ) {
                                                        if (
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架人" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架时间" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "业务类型" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "业务单号" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "凭证是否生成"
                                                        ) {
                                                            Showstable.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                            TableAllColumn.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                        }
                                                    } else {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                                default:
                                                    if (
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title !=
                                                        "提交人" &&
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title != "提交时间"
                                                    ) {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                            }
                                        }
                                        switch (
                                        listDesignAttrs.tableChecks[i].title
                                        ) {
                                            case "档案类型":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报表类型";
                                                break;
                                            case "凭证日期":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报表日期";
                                                break;
                                            case "凭证数":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报表数";
                                                break;
                                            case "凭证号最小值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报表起始号";
                                                break;
                                            case "凭证号最大值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报表截止号";
                                                break;
                                        }
                                        break;
                                    case "纳税申报表":
                                    case "审计报告":
                                    case "银行对账单":
                                    case "银行回单":
                                        //其他类型不展示的隐藏
                                        if (
                                            listDesignAttrs.tableChecks[i]
                                                .title != "过账状态" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "账簿页数" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "会计期间" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "记账人" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "位置" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "档案号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "案卷号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务类型" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务单号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证是否生成" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "匹配状态"
                                        ) {
                                            switch (
                                            state.CurrentPage.fileStatus
                                            ) {
                                                case "档案装册":
                                                case "档案归档":
                                                    if (
                                                        state.CurrentPage
                                                            .FormName == "册表"
                                                    ) {
                                                        if (
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架人" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "上架时间" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "业务类型" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "业务单号" &&
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                                .title !=
                                                            "凭证是否生成"
                                                        ) {
                                                            Showstable.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                            TableAllColumn.push(
                                                                listDesignAttrs
                                                                    .tableChecks[
                                                                i
                                                                ]
                                                            );
                                                        }
                                                    } else {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                                default:
                                                    if (
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title !=
                                                        "提交人" &&
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                            .title != "提交时间"
                                                    ) {
                                                        Showstable.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                        TableAllColumn.push(
                                                            listDesignAttrs
                                                                .tableChecks[i]
                                                        );
                                                    }
                                                    break;
                                            }
                                        }
                                        switch (
                                        listDesignAttrs.tableChecks[i].title
                                        ) {
                                            case "档案类型":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报告类型";
                                                break;
                                            case "凭证日期":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报告日期";
                                                break;
                                            case "凭证数":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报告数";
                                                break;
                                            case "凭证号最小值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报告起始号";
                                                break;
                                            case "凭证号最大值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报告截止号";
                                                break;
                                        }
                                        break;
                                    case "我的申请单":
                                        //其他类型不展示的隐藏
                                        if (
                                            listDesignAttrs.tableChecks[i]
                                                .title != "负责人" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "归还日期" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "是否超期" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "备注" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "超期天数"
                                        ) {
                                            Showstable.push(
                                                listDesignAttrs.tableChecks[i]
                                            );
                                            TableAllColumn.push(
                                                listDesignAttrs.tableChecks[i]
                                            );
                                        }
                                        break;
                                    case "待上架":
                                    case "已上架":
                                    case "档案移交":
                                        //其他类型不展示的隐藏
                                        if (
                                            listDesignAttrs.tableChecks[i]
                                                .title != "过账状态" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "记账人" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证日期" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "册号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "总册数" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证号最小值" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证号最大值" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务类型" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "业务单号" &&
                                            listDesignAttrs.tableChecks[i]
                                                .title != "凭证是否生成"
                                        ) {
                                            if (
                                                state.FileType == "待上架" ||
                                                state.FileType == "档案移交"
                                            ) {
                                                if (
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "位置" &&
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "上架人" &&
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "上架时间" &&
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "匹配状态" &&
                                                    // listDesignAttrs.tableChecks[
                                                    //     i
                                                    // ].title != "负责人" &&
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "编制日期" &&
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "账簿页数"
                                                ) {
                                                    Showstable.push(
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                    );
                                                    TableAllColumn.push(
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                    );
                                                }
                                            } else {
                                                if (
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "提交人" &&
                                                    listDesignAttrs.tableChecks[
                                                        i
                                                    ].title != "提交时间"
                                                ) {
                                                    Showstable.push(
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                    );
                                                    TableAllColumn.push(
                                                        listDesignAttrs
                                                            .tableChecks[i]
                                                    );
                                                }
                                            }
                                        }
                                        switch (
                                        listDesignAttrs.tableChecks[i].title
                                        ) {
                                            case "凭证数":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "档案数量";
                                                break;
                                            case "凭证号最小值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报告起始号";
                                                break;
                                            case "凭证号最大值":
                                                listDesignAttrs.tableChecks[
                                                    i
                                                ].title = "报告截止号";
                                                break;
                                        }
                                        break;
                                    default:
                                        Showstable.push(
                                            listDesignAttrs.tableChecks[i]
                                        );
                                        TableAllColumn.push(
                                            listDesignAttrs.tableChecks[i]
                                        );
                                        break;
                                }
                            } else {
                                TableAllColumn.push(
                                    listDesignAttrs.tableChecks[i]
                                );
                            }
                        }
                        if (
                            state.BasicConfigData?.isSort == "是" &&
                            state.MobileOperation
                        ) {
                            //默认有排序功能
                            Showstable.push(state.TableOperationColumn);
                        }
                    }
                    let searchLists = [];
                    if (listDesignAttrs.searchLists != null) {
                        let listSearch = [];
                        for (
                            let j = 0;
                            j < listDesignAttrs.searchLists.length;
                            j++
                        ) {
                            let data = listDesignAttrs.searchLists[j];
                            searchLists.push({
                                value: data.value.replace("-", "_"),
                                label: data.label,
                                displayType: data.displayType,
                                sourceData:
                                    payload.result.data[data.value].props
                                        .sourceData,
                                propType:
                                    payload.result.data[data.value].props.type,
                            });
                            listDesignAttrs.searchLists[
                                j
                            ].value = listDesignAttrs.searchLists[
                                j
                            ].value.replace("-", "_");
                            switch (state.FileType) {
                                case "我的申请单":
                                    //其他类型不展示的隐藏
                                    if (
                                        listDesignAttrs.searchLists[j].label !=
                                        "归还状态" &&
                                        listDesignAttrs.searchLists[j].label !=
                                        "是否超期"
                                    ) {
                                        listSearch.push(searchLists[j]);
                                    }
                                    break;
                                case "待上架":
                                case "已上架":
                                case "档案移交":
                                    if (
                                        listDesignAttrs.searchLists[j].label !=
                                        "匹配状态" &&
                                        listDesignAttrs.searchLists[j].label !=
                                        "编号" &&
                                        listDesignAttrs.searchLists[j].label !==
                                        "业务单号" &&
                                        listDesignAttrs.searchLists[j].label !==
                                        "业务单据号" &&
                                        listDesignAttrs.searchLists[j].label !=
                                        "册号"
                                    ) {
                                        if (
                                            state.CurrentPage.FormName == "册表"
                                        ) {
                                            if (
                                                listDesignAttrs.searchLists[j]
                                                    .label != "档案号" &&
                                                listDesignAttrs.searchLists[j]
                                                    .label != "案卷号"
                                            ) {
                                                listSearch.push(searchLists[j]);
                                            }
                                        } else {
                                            listSearch.push(searchLists[j]);
                                        }
                                    }
                                    break;
                                default:
                                    if (state.FileType === "原始凭证") {
                                        if (
                                            listDesignAttrs.searchLists[j]
                                                .label !== "公司名称" &&
                                            listDesignAttrs.searchLists[j]
                                                .label !== "会计期间" &&
                                            listDesignAttrs.searchLists[j]
                                                .label !== "所属年度"
                                        ) {
                                            if (
                                                listDesignAttrs.searchLists[j]
                                                    .label === "编号"
                                            )
                                                searchLists[j].label = "凭证号";
                                            if (
                                                listDesignAttrs.searchLists[j]
                                                    .label === "匹配状态"
                                            )
                                                searchLists[j].label = "状态";
                                            listSearch.push(searchLists[j]);
                                        }
                                    } else {
                                        if (
                                            listDesignAttrs.searchLists[j]
                                                .label !== "匹配状态" &&
                                            listDesignAttrs.searchLists[j]
                                                .label !== "编号" &&
                                            listDesignAttrs.searchLists[j]
                                                .label !== "业务单号"
                                        ) {
                                            if (
                                                state.CurrentPage.FormName ==
                                                "册表"
                                            ) {
                                                if (
                                                    listDesignAttrs.searchLists[
                                                        j
                                                    ].label != "档案号" &&
                                                    listDesignAttrs.searchLists[
                                                        j
                                                    ].label != "案卷号"
                                                ) {
                                                    listSearch.push(
                                                        searchLists[j]
                                                    );
                                                }
                                            } else {
                                                listSearch.push(searchLists[j]);
                                            }
                                        }
                                    }
                                    break;
                            }
                        }

                        listDesignAttrs.searchLists.splice(
                            0,
                            listDesignAttrs.searchLists.length
                        );
                        listSearch.map((item) =>
                            listDesignAttrs.searchLists.push(item)
                        );
                        //listDesignAttrs["searchLists"] = searchLists;
                        // if (state.FileType == "我的申请单") {
                        //     listDesignAttrs.searchLists.splice(0, listDesignAttrs.searchLists.length);
                        //     listSearch.map(item => listDesignAttrs.searchLists.push(item));
                        // } else {
                        //     if (state.FileType == '原始凭证'){

                        //     }
                        //     listDesignAttrs["searchLists"] = searchLists;

                        // }
                    }
                }
                let result = {
                    Showstable,
                    checkColumns: Showstable.map((item) => item.dataIndex),
                    TableAllColumn,
                    StatusField: StatusField?.id,
                    formId,
                    fields,
                    SourcesData: SourcesData?.id,
                    FileTypeField: FileTypeField?.id,
                    PositionField: PositionField?.id,
                    PositionKeysField: PositionKeysField?.id,
                    FileArrayField: FileArrayField?.id,
                    AuditStatusField: AuditStatusField?.id,
                    ArchivesField: ArchivesField?.id,
                    LendStatusField: LendStatusField?.id,
                    BorrowTypeField: BorrowTypeField?.id,
                    CategoryField: CategoryField?.id,
                    RetentionPeriodField: RetentionPeriodField?.id,
                    BorrowerField: BorrowerField?.id,
                    BorrowerIdField: BorrowerIdField?.id,
                    searchList:
                        listDesignAttrs != null
                            ? listDesignAttrs.searchLists != null
                                ? listDesignAttrs.searchLists
                                : []
                            : [],
                    Showstable,
                    TableAllColumn,
                    //StatusField: StatusField?.id,
                    formId,
                    fields,
                    SourcesData: SourcesData?.id,
                    FileTypeField: FileTypeField?.id,
                    PositionField: PositionField?.id,
                    PositionKeysField: PositionKeysField?.id,
                    VolumeTableID: VolumeTableID?.id,
                    NumberField: Number?.id,
                    SortNumberField: SortNumber?.id,
                    CompanyNameField: CompanyName?.id,
                    YearField: Year?.id,
                    AccountingPeriodField: AccountingPeriod?.id,
                    SecurityField: SecurityField?.id,
                    MappingStatusField: MappingStatusField?.id,
                    MstIdsField: MstIdsField?.id,
                    BusinessTypeField: BusinessTypeField?.id,
                    BusinessDocNoField: BusinessDocNoField?.id,
                    AppendicesField: AppendicesField?.id,
                    PutawayPeopleField: PutawayPeopleField?.id,
                    PutawayTimeField: PutawayTimeField?.id,
                    DocTypeField: DocTypeField?.id,
                    ReturnStatusField: ReturnStatusField?.id,
                    UserNameField: UserNameField?.id.replace("-", "_"),
                    SubmitTimeField: SubmitTimeField?.id.replace("-", "_"),
                    SubmitterField: SubmitterField?.id.replace("-", "_"),
                    SubmitterDateField: SubmitterDateField?.id.replace(
                        "-",
                        "_"
                    ),
                    searchList:
                        listDesignAttrs != null
                            ? listDesignAttrs.searchLists != null
                                ? listDesignAttrs.searchLists
                                : []
                            : [],
                };
                if (properties) {
                    result.properties = properties;
                }
                return result;
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getListData: {
        //获取列表数据
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/ArchivesService/TableListData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imageListData = payload.result;
                const imageListDataCount = payload.result?.length;
                return { imageListData: imageListData, imageListDataCount };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    ImageDocListExport: {
        //导出方法
        payload: (params) => {
            return sxAjax.post(
                "/api/services/app/AppImageDocListExcelExporter/ImageDetailsListExportToFiles",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let url =
                    abp.setting.get("App.Api.Default.Host") +
                    "File/downloadTempFile?" +
                    stringify(payload.result);
                window.open(url);
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    SaveFormDataBatch: {
        //批量保存表单
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/AppObject/SaveFormDataBatch",
                params.AppFormDataDto
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    getBasicConfig: {
        //获取基础配置
        payload: (params) => {
            return sxAjax.get(
                "/api/services/app/BasicConfig/GetBasicConfigData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let BasicConfigData = payload.result;
                return { BasicConfigData };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },

    UpdateFileStatus: {
        payload: (params) => {
            return sxAjax.post(
                "/api/services/app/BasicConfig/UpdateFilesStatus",
                params.UpdateFilesDto
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    SerialNumberValidation: {
        payload: (params) => {
            return sxAjax.post(
                "/api/services/app/ArchivesB/SerialNumberValidation",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) { },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    InsertLogData: {
        payload: (params) => {
            return sxAjax.post(
                "/api/services/app/OpeStepRecord/InsertLogData",
                params.StepRecordDto
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getVolumeData: {
        payload: (params) =>
            sxAjax.post("/api/services/app/ArchivesB/CheckVoucherList", params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { VolumeData: payload.result };
                return data;
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getVoucherData: {
        payload: (params) =>
            sxAjax.post(
                "/api/services/app/ArchivesB/GetVoucherMapping",
                params
            ),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var data = { VolumeData: payload.result };
                return data;
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getGuid: {
        payload: () => {
            return sxAjax.get(
                "/uuid/get"
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let newGuid = payload.result;
                return { newGuid };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    }
};
