import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
declare var abp: any;
export default {
    namespace: 'reportDesign',
    initialState: {
        searchFillInData: [{//搜索条件值
            FormId: '',
            Filters: [
                { field: '', values: [''] }
            ],
            nextProcessFormID:''
        }],
        documentView: '',//影像字段名
        userInfo: {},
        imageListData: [],//列表表格数据源
        imageListDataCount: 0,//列表数据总数
        task: {},//选中行task数据
        formDocumentVisible: false,//影像弹出页面隐藏显示
        TaskInfosByIncidentId: [],//Web页面选中行流程数据
    },
    setsearchFillInData: (searchFillInData, _) => {
        return { searchFillInData };
    },
    setuserInfo: (userInfo, _) => {
        return { userInfo };
    },
    setDocumentView: (documentView, _) => {
        return { documentView }
    },
    setformDocumentVisible: (formDocumentVisible, _) => {
        return { formDocumentVisible };
    },
    setFormTask: (task, _) => {
        return { task };
    },
    scanDelete: {//扫描Web端删除方法
        payload: (params) => {
            return sxAjax.post('api/services/app/AppImageForm/ImageDocumentDelete', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imagetotalCount = payload.result.totalCount;//总行数
                return ({ imagetotalCount });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getListData: {//获取影像列表数据
        payload: (params) => {
            return sxAjax.post('api/services/app/AppImageForm/ImageListData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imageListData = payload.result;
                for (let i = 0; i < payload.result.length; i++) {
                    payload.result[i].formId = state.searchFillInData.FormId;
                    let Task = state.TaskInfosByIncidentId.find(function (value) {//获取批次号字段名
                        return value.incidentId === payload.result[i].incidentId
                    });
                    if (Task != undefined) {
                        payload.result[i].stepName = Task.stepName;
                        payload.result[i].taskId = Task.taskId;
                        payload.result[i].stepId = Task.stepId;
                        payload.result[i].status = Task.status;
                    }
                }
                const imageListDataCount = payload.result.length
                return ({ imageListData: imageListData, imageListDataCount });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    ImageDocListExport: {//导出方法
        payload: (params) => {
            return sxAjax.post('/api/services/app/AppImageDocListExcelExporter/ImageDetailsListExportToFiles', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let url = abp.setting.get("App.Api.Default.Host") + "File/downloadTempFile?" + stringify(payload.result);
                window.open(url);
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    GetTaskInfo: {
        payload: (params) => {
            //return sxAjax.get(abp.setting.get("App.Api.Workflow.Host") + 'api/services/app/Task/GetTaskInfoByIncidentId', params);
            return sxAjax.get(abp.setting.get("App.Api.Workflow.Host") + 'api/services/app/Task/GetTaskInfoByIncidentId', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let task = {
                    formId: state.searchFillInData.FormId,
                    incidentId: payload.result.incidentId,
                    taskId: payload.result.taskId,
                    stepId: payload.result.stepId,
                    status: payload.result.status
                };
                return { task };
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    },
    GetTaskInfosByIncidentId: {
        payload: (params) => {
            return sxAjax.post(abp.setting.get("App.Api.Workflow.Host")+'api/services/app/Task/GetTaskInfosByIncidentId', params);
            //return sxAjax.post('https://localhost:44317/api/services/app/Task/GetTaskInfosByIncidentId', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return { TaskInfosByIncidentId: payload.result }
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    }
}