import { sxAjax } from '@/commons/ajax';

export default {
    namespace: 'role',
    initialState: {
        data: [],
        roleUsersData: [],
        roleUsersDataByNames: [],
        loading: false
    },
    getRoles: {
        payload: (params) => sxAjax.get('api/services/app/Role/GetRoles', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { totalCount, items = [] } = payload.result;
                return {
                    data: {
                        items: items,
                        totalCount: totalCount
                    }
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getRolesByNames: {
        payload: (params) => sxAjax.post('api/services/app/Role/GetRolesByNames', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { totalCount, items = [] } = payload.result;
                return {
                    data: {
                        items: items
                    }
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getRolesByIds: {
        payload: (params) => sxAjax.post('api/services/app/Role/GetRolesByIds', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { totalCount, items = [] } = payload.result;
                return {
                    data: {
                        items: items
                    }
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getRoleUsers: {
        payload: (params) => sxAjax.post('api/services/app/Role/GetRoleUsersByIds', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { totalCount, items = [] } = payload.result;
                return {
                    roleUsersData: {
                        items: items
                    }
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getRoleUsersByIds: {
        payload: (params) => sxAjax.post('api/services/app/Role/GetRoleUsersByIds', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { totalCount, items = [] } = payload.result;
                return {
                    roleUsersDataByNames: {
                        items: items
                    }
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getRoleUsersByNames: {
        payload: (params) => sxAjax.post('api/services/app/Role/GetRoleUsersByNames', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { totalCount, items = [] } = payload.result;
                return {
                    roleUsersDataByNames: {
                        items: items
                    }
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    GetFilterRoles: {
        payload: (params) => sxAjax.get('api/services/app/Role/GetFilterRoles', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {
                    data: payload.result
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getRoleForEdit: {
        payload: (params) => sxAjax.get('api/services/app/Role/GetRoleForEdit', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    createOrUpdateRole: {
        payload: (params) => sxAjax.post('api/services/app/Role/createOrUpdateRole', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    deleteRole: {
        payload: (params) => sxAjax.del('api/services/app/Role/DeleteRole', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => ({ loading: false }),
        }
    }
}