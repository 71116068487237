import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
declare var abp: any;
export default {
    namespace: 'ComprehensivelistDesign',
    initialState: {
        flag: false,
        InitialDisplay: true,//默认显示空
        searchList: [],//列表设计-搜索选中标签
        TableAllColumn: [],//列表所有字段,
        Showstable: [],//列表设计-表格选中标签,
        imageListData: [],//列表表格数据源
        imageListDataCount: 0,//列表数据总数
        searchFillInData: {//搜索条件值
            FormId: '',
            Filters: [
            ],
            pagesize: 10,
            nowpagecount: 1,
            QueryVolume: "案卷",
            MaxID: 0,

        },
        selectedRows: [],//表格选中行数据
        StatusField: '',//档案状态字段名
        SourcesData: '',//数据来源字段名
        FileTypeField: '',//档案类型字段名
        VolumeTableID: '',//册表MstID
        formId: '',
        BookFormID: '',
        MstId: '',
        FileType: '',//档案类型
        fields: [],//表单所有数据库字段
        loading: true,
        PositionField: '',//位置名
        PositionKeysField: '',//位置keys

        companylist: []
    },


    /**组件卸载 */
    setUnmount: () => {
        return {
            flag: false,
            InitialDisplay: true,//默认显示空
            searchList: [],//列表设计-搜索选中标签
            TableAllColumn: [],//列表所有字段,
            Showstable: [],//列表设计-表格选中标签,
            imageListData: [],//列表表格数据源
            imageListDataCount: 0,//列表数据总数
            searchFillInData: {//搜索条件值
                FormId: '',
                Filters: [
                ],
                pagesize: 10,
                nowpagecount: 1,
                QueryVolume: "案卷",
                MaxID: 0,
            },
            selectedRows: [],//表格选中行数据
            StatusField: '',//档案状态字段名
            SourcesData: '',//数据来源字段名
            FileTypeField: '',//档案类型字段名
            VolumeTableID: '',//册表MstID
            formId: '',
            BookFormID: '',
            MstId: '',
            FileType: '',//档案类型
            fields: [],//表单所有数据库字段
            loading: true,
            PositionField: '',//位置名
            PositionKeysField: '',//位置keys

            companylist: []
        }
    },
    setflag: (flag, _) => {
        return { flag };
    },
    setsearchFillInData: (searchFillInData, _) => {
        return { searchFillInData };
    },
    setInitialDisplay: (InitialDisplay, _) => {
        return { InitialDisplay };
    },
    setSelectedRows: (selectedRows, _) => {
        return { selectedRows };
    },
    setMstId: (MstId, _) => {
        return { MstId };
    },
    setFileType: (FileType, _) => {
        return { FileType };
    },
    setBookFormID: (BookFormID, _) => {
        return { BookFormID };
    },
    getListData: {//获取列表数据

        payload: (params) => {
            return sxAjax.post('/api/services/app/ComprehensiveDocumentQuery/TableListData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imageListData = payload.result.data;
                const imageListDataCount = payload.result.rowcount
                let MaxID = payload.result.MaxID
                return ({ imageListData: imageListData, imageListDataCount, MaxID });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },

    getCompanyData: {//获取列表数据
        payload: () => {
            return sxAjax.get('/api/services/app/ComprehensiveDocumentQuery/GetCompany');
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                ;
                let companylist = payload.result;
                return ({ companylist: companylist });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },

    AddCar: {//获取列表数据

        payload: (cars) => {

            return sxAjax.post('/api/services/app/LendingLibrary/AddCart', cars);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
}