import { sxAjax } from '@/commons/ajax';
export default {
    namespace: 'metadataDesign',
    initialState: {
        loading: false,
        loadComplete: false,
        documentTypeId: "",
        documentTypeName: "",
        mstId: "",
        formId: "",
        data: null,
        fields: [],
        properties: {//不保存
            labelStyle: "row",
            layout: "3",//布局 单列、双列、三列
            default: "1",//不可见字段赋值,
        },
        values: { data: {} },
    },
    setUnmount: () => {
        return {
            loadComplete: "",
            documentTypeId: "",
            documentTypeName: "",
            mstId: "",
            formId: "",
            data: null,
            fields: [],
            properties: {//不保存
                labelStyle: "row",
                layout: "3",//布局 单列、双列、三列
                default: "1",//不可见字段赋值,
            },
            values: { data: {} },
        }
    },
    initState: (s, _) => {
        return s
    },
    /**
     * 设置表单信息加载完成可以渲染了
     */
    setLoadComplete: (_, _state) => {
        return { loadComplete: true };
    },
    getForm: {
        payload: (params) => {
            return sxAjax.get('api/services/app/DocumentForm/GetFormInfo', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true, }),
            resolve(state, { payload = {} }) {
                const { documentTypeId, formId, data, fields, properties, } = payload.result;
                const result = { documentTypeId, formId, data, fields, }
                if (properties) {
                    result.properties = properties;
                }
                return (result);
            },
            complete: (state, action) => {
                return ({ loading: false, });
            },
        }
    },
    getGuid: {
        payload: (params) => {
            return sxAjax.get('uuid/get', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return ({});
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    saveFormData: {
        payload: (params) => {
            return sxAjax.post('api/services/app/AppObject/SaveFormData', { ...params, ...{ runFormula: false } });
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    verifyFormData: {
        payload: (params) => {
            return sxAjax.post(
                "documentservice/api/Receipt/VerifyFormData",
                { ...params, ...{ runFormula: false } }
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },

    getFormData: {
        payload: (params) => {
            return sxAjax.get('api/services/app/MetaDataArchivesTree/GetFormData', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const { mstId, formData, formId } = payload.result;
                if (!formData.data) {
                    formData.data = {}
                }
                return ({ mstId, values: formData, formId });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getFormEventId: {
        payload: (params) => {
            return sxAjax.get('api/services/app/AppObject/GetEventId', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const eventId = payload.result;
                return ({ eventId });
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
}