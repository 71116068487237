import { sxAjax } from '@/commons/ajax';
declare var abp: any;
export default{
    namespace: 'treeDesign',
    initialState:{
        collapsed: false, //展开
        formTree: [],
        fileTypeData:[],
        loading: true,
    },
    setCollapsed: (collapsed, _) => {
        return { collapsed };
    },
    scanTreeDatascanTreeData: {//档案类型树形结构数据获取
        payload: (params) => {
            return sxAjax.get('api/services/app/ArchivesService/GetFileTypesForm');
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let formTree = payload.result;
                return ({ formTree });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },   
    getFileType: {
        payload:(params)=>sxAjax.get('api/services/app/ArchivesService/GetFileTypeInfo',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={fileTypeData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    }
}