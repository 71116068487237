import { sxAjax } from '@/commons/ajax';
declare var abp: any;
export default {
    //会计账簿
    namespace: 'AccountbooklistDesign',
    initialState: {
        InitialDisplay: true,//默认显示空
        searchList: [],//列表设计-搜索选中标签
        TableAllColumn: [],//列表所有字段,
        Showstable: [],//列表设计-表格选中标签,
        imageListData: [],//列表表格数据源
        imageListDataCount: 0,//列表数据总数
        searchFillInData: {//搜索条件值
            FormId: '',
            Filters: [
            ],
            pagesize: 10,
            nowpagecount: 1,
            QueryVolume: "案卷",
            MaxID: 0,

        },

        QueryCol: ["公司名称", "档案类型", "标题", "密级", "保管期限", "案卷号", "所属年度", "会计期间", "类目", "档案号"],//案卷
        Querylabel: ["公司名称", "账簿类型", "标题", "密级", "保管期限", "案卷号", "所属年度", "会计期间", "类目", "档案号"],//案卷
        QueryFilters: ["like", "like", "like", "equal", "equal", "like", "equal", "equal", "equal", "like"],//案卷
        QueryTableCol: ["案卷号", "档案号", "标题", "公司代码", "公司名称", "所属年度", "会计期间", "总册数", "保管年限", "Mstid"],//案卷
        QueryTableColLabel: ["案卷号", "档案号", "标题", "公司代码", "公司名称", "所属年度", "会计期间", "总册数", "保管年限", "Mstid"],//案卷

        InQueryCol: ["公司", "档案类型", "开始时间", "结束时间", "标题", "密级", "保管期限", "档案号", "所属年度", "会计期间", "类目", "档案号"],//卷内
        InQuerylabel: ["公司", "账簿类型", "账簿起始日期", "账簿结束日期", "标题", "密级", "保管期限", "案卷号", "所属年度", "会计期间", "类目", "档案号"],//卷内
        InQueryFilters: ["like", "equal", "equalmore", "equalless", "like", "equal", "equal", "like", "equal", "equal", "equal", "like"],//卷内
        InQueryTableCol: ["案卷号", "档案号", "编号", "标题", "公司名称", "公司代码", "所属年度", "会计期间", "凭证日期", "制单人", "审核人", "记账人", "保管年限", "密级", "附件数", "Mstid"],//卷内
        InQueryTableColLabel: ["案卷号", "档案号", "账簿编号", "标题", "公司名称", "公司代码", "所属年度", "会计期间", "账簿日期", "制单人", "审核人", "记账人", "保管年限", "密级", "附件数", "Mstid"],//卷内

        selectedRows: [],//表格选中行数据
        StatusField: '',//档案状态字段名
        SourcesData: '',//数据来源字段名
        FileTypeField: '',//档案类型字段名
        VolumeTableID: '',//册表MstID
        formId: '',
        BookFormID: '',
        MstId: '',
        FileType: '',//档案类型
        fields: [],//表单所有数据库字段
        loading: true,
        PositionField: '',//位置名
        PositionKeysField: ''//位置keys
    },

    /**组件卸载 */
    setUnmount: () => {
        return {
            InitialDisplay: true,//默认显示空
            searchList: [],//列表设计-搜索选中标签
            TableAllColumn: [],//列表所有字段,
            Showstable: [],//列表设计-表格选中标签,
            imageListData: [],//列表表格数据源
            imageListDataCount: 0,//列表数据总数
            searchFillInData: {//搜索条件值
                FormId: '',
                Filters: [
                ],
                pagesize: 10,
                nowpagecount: 1,
                QueryVolume: "案卷",
                MaxID: 0,

            },

            QueryCol: ["公司名称", "档案类型", "标题", "密级", "保管期限", "案卷号", "所属年度", "会计期间", "类目", "档案号"],//案卷
            Querylabel: ["公司名称", "账簿类型", "标题", "密级", "保管期限", "案卷号", "所属年度", "会计期间", "类目", "档案号"],//案卷
            QueryFilters: ["like", "like", "like", "equal", "equal", "like", "equal", "equal", "equal", "like"],//案卷
            QueryTableCol: ["案卷号", "档案号", "标题", "公司代码", "公司名称", "所属年度", "会计期间", "总册数", "保管年限", "Mstid"],//案卷
            QueryTableColLabel: ["案卷号", "档案号", "标题", "公司代码", "公司名称", "所属年度", "会计期间", "总册数", "保管年限", "Mstid"],//案卷

            InQueryCol: ["公司", "档案类型", "开始时间", "结束时间", "标题", "密级", "保管期限", "档案号", "所属年度", "会计期间", "类目", "档案号"],//卷内
            InQuerylabel: ["公司", "账簿类型", "账簿日期_开始", "账簿日期_结束", "标题", "密级", "保管期限", "案卷号", "所属年度", "会计期间", "类目", "档案号"],//卷内
            InQueryFilters: ["like", "equal", "equalmore", "equalless", "like", "equal", "equal", "like", "equal", "equal", "equal", "like"],//卷内
            InQueryTableCol: ["案卷号", "档案号", "编号", "标题", "公司名称", "公司代码", "所属年度", "会计期间", "凭证日期", "制单人", "审核人", "记账人", "保管年限", "密级", "附件数", "Mstid"],//卷内
            InQueryTableColLabel: ["案卷号", "档案号", "凭证号", "标题", "公司名称", "公司代码", "所属年度", "会计期间", "凭证日期", "制单人", "审核人", "记账人", "保管年限", "密级", "附件数", "Mstid"],//卷内

            selectedRows: [],//表格选中行数据
            StatusField: '',//档案状态字段名
            SourcesData: '',//数据来源字段名
            FileTypeField: '',//档案类型字段名
            VolumeTableID: '',//册表MstID
            formId: '',
            BookFormID: '',
            MstId: '',
            FileType: '',//档案类型
            fields: [],//表单所有数据库字段
            loading: true,
            PositionField: '',//位置名
            PositionKeysField: ''//位置keys

        }
    },
    setsearchFillInData: (searchFillInData, _) => {
        return { searchFillInData };
    },
    setInitialDisplay: (InitialDisplay, _) => {
        return { InitialDisplay };
    },
    setSelectedRows: (selectedRows, _) => {
        return { selectedRows };
    },
    setMstId: (MstId, _) => {
        return { MstId };
    },
    setFileType: (FileType, _) => {
        return { FileType };
    },
    setBookFormID: (BookFormID, _) => {
        return { BookFormID };
    },
    getForm: {
        payload: (params) => {
            ;
            return sxAjax.get('api/services/app/AccountbookDirectoryQuery/GetForm?formId=' + params.formId + "&Documenttype=会计账簿");
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let QueryCol = state.QueryCol;
                let QueryFilters = state.QueryFilters;
                let QueryTableCol = state.QueryTableCol;
                let Querylabel = state.Querylabel;
                let QueryTableColLabel = state.QueryTableColLabel;
                if (state.searchFillInData.FormId == "ba8774f1-e5ab-fbcf-8e97-bfafadc8db10") {
                    QueryCol = state.InQueryCol;
                    QueryFilters = state.InQueryFilters;
                    QueryTableCol = state.InQueryTableCol;
                    Querylabel = state.InQuerylabel;
                    QueryTableColLabel = state.InQueryTableColLabel;
                }
                const { formId, formName, formType, data, properties, fields } = payload.result;

                let listDesignAttrs = [];
                let searchLists = [];
                QueryCol.find(function (valueCol, index) {//所属年度
                    let colname = payload.result.fields.find(function (value) {//公司名称
                        return value.name === valueCol
                    });
                    if (colname != undefined) {
                        colname["filtertype"] = QueryFilters[index]
                        if (valueCol == "档案类型") {
                            payload.result.data[colname.id].props.sourceData = payload.result.documenttype;
                            colname.type = "SingleElection";
                            searchLists.push({ "value": colname.id, "label": Querylabel[index], "displayType": colname.type, sourceData: payload.result.data[colname.id].props.sourceData, filtertype: colname.filtertype, defaultvalue: "", sqlcom: "档案类型" });
                        }
                        // else if (valueCol == "状态") {
                        //     state.searchFillInData.Filters.push({ field: colname.id, values: ["已上架"], type: "InputText", filtertype: colname.filtertype, sqlcom: colname.name })
                        // }
                        else if (valueCol == "类目") {
                            state.searchFillInData.Filters.push({ field: colname.id, values: ["会计账簿"], type: "InputText", filtertype: colname.filtertype, sqlcom: colname.name })
                        }

                        else if (valueCol == "开始时间") {
                            state.searchFillInData.Filters.push({ field: colname.id, values: [""], type: "InputText", filtertype: colname.filtertype, sqlcom: colname.name })
                            searchLists.push({ "value": colname.id, "label": Querylabel[index], "displayType": colname.type, sourceData: payload.result.data[colname.id].props.sourceData, filtertype: colname.filtertype, defaultvalue: "", sqlcom: "编号_开始" });
                        }
                        else if (valueCol == "结束时间") {
                            state.searchFillInData.Filters.push({ field: colname.id, values: [""], type: "InputText", filtertype: colname.filtertype, sqlcom: colname.name })
                            searchLists.push({ "value": colname.id, "label": Querylabel[index], "displayType": colname.type, sourceData: payload.result.data[colname.id].props.sourceData, filtertype: colname.filtertype, defaultvalue: "", sqlcom: "编号_结束" });
                        }
                        else {
                            searchLists.push({ "value": colname.id, "label": Querylabel[index], "displayType": colname.type, sourceData: payload.result.data[colname.id].props.sourceData, filtertype: colname.filtertype, defaultvalue: "", sqlcom: colname.name });
                        }
                    }
                });
                let tableChecks = [];

                QueryTableCol.find(function (valueCol, indexcol) {
                    let iscol = false
                    let item;
                    payload.result.fields.forEach(function (value) {//所属年度
                        if (valueCol === value.name) {
                            item = value;
                            if (valueCol == "档案类型") {
                                payload.result.data[item.id].props.sourceData = payload.result.documenttype
                            }
                            tableChecks.push({ title: QueryTableColLabel[indexcol], dataIndex: item.id, key: item.dataField, checkeds: true, sorter: true, sourceData: payload.result.data[item.id].props.sourceData });
                        }
                    });

                });


                listDesignAttrs["searchLists"] = searchLists;
                listDesignAttrs["tableChecks"] = tableChecks;


                let Showstable = [];
                let TableAllColumn = [];
                let StatusField = payload.result.fields.find(function (value) {//获取状态字段名
                    return value.customType === "Status"
                });
                let SourcesData = payload.result.fields.find(function (value) {//获取数据来源字段名
                    return value.customType === "SourcesData"
                });
                let FileTypeField = payload.result.fields.find(function (value) {//获取档案类型字段名
                    return value.customType === "DocumentType"
                });
                let VolumeTableID = payload.result.fields.find(function (value) {//获取册表MstID字段名
                    return value.customType === "VolumeTableID"
                });
                let PositionField = payload.result.fields.find(function (value) {//获取档案类型字段名
                    return value.customType === "Position"
                });
                let PositionKeysField = payload.result.fields.find(function (value) {//获取档案类型字段名
                    return value.customType === "PositionKeys"
                });
                if (listDesignAttrs != null) {
                    if (listDesignAttrs.tableChecks != null) {
                        for (let i = 0; i < listDesignAttrs.tableChecks.length; i++) {
                            listDesignAttrs.tableChecks[i].dataIndex = listDesignAttrs.tableChecks[i].dataIndex.replace('-', '_');
                            listDesignAttrs.tableChecks[i].key = listDesignAttrs.tableChecks[i].key.replace('-', '_');
                            if (listDesignAttrs.tableChecks[i].checkeds == true) {
                                Showstable.push(listDesignAttrs.tableChecks[i])
                                TableAllColumn.push(listDesignAttrs.tableChecks[i])
                            } else {
                                TableAllColumn.push(listDesignAttrs.tableChecks[i])
                            }
                        }
                    }
                    if (listDesignAttrs.searchList != null) {
                        for (let i = 0; i < listDesignAttrs.searchList.length; i++) {
                            listDesignAttrs.searchList[i].value = listDesignAttrs.searchList[i].value.replace('-', '_')
                        }
                    }
                }
                // ;
                let result = {
                    Showstable, TableAllColumn, StatusField: StatusField?.id, formId, fields, SourcesData: SourcesData?.id, FileTypeField: FileTypeField?.id,
                    PositionField: PositionField?.id, PositionKeysField: PositionKeysField?.id,
                    searchList: listDesignAttrs != null ? (listDesignAttrs.searchLists != null ? listDesignAttrs.searchLists : []) : [],
                };
                if (properties) {
                    result.properties = properties;
                }
                return (result);

            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getListData: {//获取列表数据

        payload: (params) => {

            return sxAjax.post('/api/services/app/AccountbookDirectoryQuery/TableListData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

                let imageListData = payload.result.data;
                const imageListDataCount = payload.result?.length;
                let MaxID = payload.result.MaxID
                return ({ imageListData: imageListData, imageListDataCount, MaxID });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },

    AddCar: {//获取列表数据

        payload: (cars) => {
            return sxAjax.post('/api/services/app/LendingLibrary/AddCart', cars);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
}