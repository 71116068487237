import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
import axios from 'axios';
import { getLoginUser } from '@/commons';
declare var abp: any;
export default {
    namespace: 'listForm',
    initialState: {
        FormAllList: [],//表单所有数据,
        FormAllListCount:0,//总数量
        loading: true,
    },

    getFormData: {//获取列表数据
        payload: (params) => {
            return sxAjax.get('/api/services/app/BasicConfig/GetBasicConfigData',params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) { 
                let FormAllList = payload.result;
                const FormAllListCount = payload.result.length
                return ({ FormAllList: FormAllList,FormAllListCount });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    SaveFormDataBatch: {
        payload: (params) => { 
            return sxAjax.post('api/services/app/BasicConfig/InsertBasicConfigData', params.BasicConfigData[0]);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    UpdateFormDataBatch: {
       
        payload: (params) => {   
            return sxAjax.post('/api/services/app/BasicConfig/UpdateBasicConfigData',  params.BasicConfigData[0]);
        },
        reducer: {
            
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },


}