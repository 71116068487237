import { sxAjax } from "@/commons/ajax";
import { stringify } from "qs";
import axios from "axios";
import { getLoginUser } from "@/commons";
import moment from "moment";
declare var abp: any;
export default {
    namespace: "borrowDetail",
    initialState: {
        InitialDisplay: true, //默认显示空
        searchList: [], //列表设计-搜索选中标签
        TableAllColumn: [], //列表所有字段,
        TableOperationColumn: {}, //列表操作列
        Showstable: [], //列表设计-表格选中标签,
        imageListData: [], //列表表格数据源
        imageListDataCount: 0, //列表数据总数
        TotalListData: [], //列表表格数据源
        TotalListDataCount: 0, //列表数据总数
        TotalListData1: [], //列表表格数据源
        TotalListData1Count: 0, //列表数据总数
        searchFillInData: {
            //搜索条件值
            FormId: "",
            Filters: [],
        },

        QueryCol: ["案卷类型", "档案号", "公司名称", "所属年度", "类目"], //案卷
        Querylabel: ["案卷类型", "档案号", "所属公司", "所属年度", "类目"], //案卷

        formId: "",
        fields: [], //表单所有数据库字段
        loading: true,
    },
    /**组件卸载 */
    setUnmount: () => {
        return {
            InitialDisplay: true, //默认显示空
            searchList: [], //列表设计-搜索选中标签
            TableAllColumn: [], //列表所有字段,
            TableOperationColumn: {}, //列表操作列
            Showstable: [], //列表设计-表格选中标签,
            imageListData: [], //列表表格数据源
            imageListDataCount: 0, //列表数据总数
            TotalListData: [], //列表表格数据源
            TotalListDataCount: 0, //列表数据总数
            TotalListData1: [], //列表表格数据源
            TotalListData1Count: 0, //列表数据总数
            searchFillInData: {
                //搜索条件值
                FormId: "",
                Filters: [],
            },
            QueryCol: ["案卷类型", "档案号", "公司名称", "所属年度", "类目"], //案卷
            Querylabel: ["案卷类型", "档案号", "所属公司", "所属年度", "类目"], //案卷
            formId: "",
            fields: [], //表单所有数据库字段
            loading: true,
        };
    },
    setsearchFillInData: (searchFillInData, _) => {
        return { searchFillInData };
    },
    setInitialDisplay: (InitialDisplay, _) => {
        return { InitialDisplay };
    },
    setSelectedRows: (selectedRows, _) => {
        return { selectedRows };
    },

    setformId: (formId, _) => {
        return { formId };
    },

    setimageListData: (imageListData, _) => {
        return { imageListData };
    },
    setTotalListData: (TotalListData, _) => {
        return { TotalListData };
    },
    setTotalListData1: (TotalListData1, _) => {
        return { TotalListData1 };
    },
    setShowstable: (Showstable, _) => {
        return { Showstable };
    },

    getForm: {
        payload: (params) => {
            return sxAjax.get(
                "api/services/app/AppObject/GetForm?formId=" + params.formId
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let QueryCol = state.QueryCol;
                let QueryFilters = state.QueryFilters;
                let QueryTableCol = state.QueryTableCol;
                let Querylabel = state.Querylabel;
                let QueryTableColLabel = state.QueryTableColLabel;

                const {
                    formId,
                    formName,
                    formType,
                    data,
                    properties,
                    fields,
                } = payload.result;
                let listDesignAttrs = [];
                let searchLists = [];
                QueryCol.find(function (valueCol, index) {
                    //所属年度
                    let colname = payload.result.fields.find(function (value) {
                        //公司名称
                        return value.name === valueCol;
                    });

                    if (valueCol === "档案类型") {
                        searchLists.push({
                            value: "FileType",
                            label: Querylabel[index],
                            displayType: "InputText",
                            filtertype: colname.filtertype,
                            defaultvalue: "",
                            sqlcom: colname.name,
                        });
                    } else if (valueCol === "档案号") {
                        searchLists.push({
                            value: "FileNumber",
                            label: Querylabel[index],
                            displayType: colname.type,
                            filtertype: colname.filtertype,
                            defaultvalue: "",
                            sqlcom: colname.name,
                        });
                    } else if (valueCol === "公司名称") {
                        searchLists.push({
                            value: "Company",
                            label: Querylabel[index],
                            displayType: colname.type,
                            sourceData:
                                payload.result.data[colname.id].props
                                    .sourceData,
                            filtertype: colname.filtertype,
                            defaultvalue: "",
                            sqlcom: colname.name,
                        });
                    } else if (valueCol === "所属年度") {
                        searchLists.push({
                            value: "YearData",
                            label: Querylabel[index],
                            displayType: colname.type,
                            sourceData:
                                payload.result.data[colname.id].props
                                    .sourceData,
                            filtertype: colname.filtertype,
                            defaultvalue: "",
                            sqlcom: colname.name,
                        });
                    } else if (valueCol === "类目") {
                        searchLists.push({
                            value: "Category",
                            label: Querylabel[index],
                            displayType: "InputText",
                            filtertype: colname.filtertype,
                            defaultvalue: "",
                            sqlcom: colname.name,
                        });
                    }
                });
                let tableChecks = [];
                listDesignAttrs["searchLists"] = searchLists;
                listDesignAttrs["tableChecks"] = tableChecks;
                let Showstable = [];
                let TableAllColumn = [];
                Showstable.push(
                    {
                        title: "案卷类型",
                        dataIndex: "FileType",
                        key: "FileType",
                    },
                    {
                        title: "档案号",
                        dataIndex: "FileNumber",
                        key: "FileNumber",
                    },
                    { title: "所属公司", dataIndex: "Company", key: "Company" },
                    {
                        title: "所属年度",
                        dataIndex: "YearData",
                        key: "YearData",
                    },
                    {
                        title: "所属期间",
                        dataIndex: "FiscalPeriod",
                        key: "FiscalPeriod",
                    },
                    { title: "密级", dataIndex: "Secret", key: "Secret" },
                    { title: "类目", dataIndex: "Category", key: "Category" },
                    { title: "借阅统计", dataIndex: "count", key: "count" }
                );

                let result = {
                    Showstable,
                    TableAllColumn,
                    formId,
                    fields,
                    searchList:
                        listDesignAttrs != null
                            ? listDesignAttrs.searchLists != null
                                ? listDesignAttrs.searchLists
                                : []
                            : [],
                };
                if (properties) {
                    result.properties = properties;
                }
                return result;
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },

    getBorrowListData: {
        //获取列表数据 借阅查询
        payload: (params) => {
            return sxAjax.post(
                "/api/services/app/BorrowQuery/TableListData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imageListData = payload.result;
                const imageListDataCount = payload.result.length;

                return { imageListData: imageListData, imageListDataCount };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },

    getBorrowTotalListData: {
        //获取列表数据 借阅统计  0 册,1 册内文件
        payload: (params) => {
            return sxAjax.post(
                "/api/services/app/BorrowQuery/TotalTableListData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let TotalListData = payload.result;
                const TotalListDataCount = payload.result.length;

                return { TotalListData: TotalListData, TotalListDataCount };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getBorrowTotalListData1: {
        //获取列表数据 借阅统计  0 册,1 册内文件
        payload: (params) => {
            return sxAjax.post(
                "/api/services/app/BorrowQuery/TotalTableListData1",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let TotalListData1 = payload.result;
                const TotalListData1Count = payload.result.length;

                return { TotalListData1: TotalListData1, TotalListData1Count };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
};
