// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/form/:formId/:mstId',
    '/document-type/form-start/:documentTypeId',
    '/document-type/form/:documentTypeId/:mstId',
    '/EAA/imagePreview/:record',
    '/fileView',
    '/login1',
    '/login2',
    '/forgetPassword',
    '/login/:clientName?/:userinfo?',
    '/thirdlogin',
    '/v2/codeUpload',
    '/v2/editresearch/view/:mstId',
    '/v2/imageQueryPreview',
    '/v2/imageQueryMobilePreview',
    '/v2/imagePreviewold',
    '/v2/imagePreviewfile',
    '/v2/imagePreview2',
    '/v2/imagePreviewview',
    '/v2/imagePreview',
    '/v2/singleLogin',
    '/v2/singleLogout',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/form/:formId/:mstId',
    '/app1/:data/form/:data',
    '/login1',
    '/login2',
    '/forgetPassword',
    '/login/:clientName?/:userinfo?',
    '/thirdlogin',
    '/v2/codeUpload',
    '/v2/imageQueryPreview',
    '/v2/imageQueryMobilePreview',
    '/v2/imagePreviewfile',
    '/v2/singleLogin',
    '/v2/singleLogout',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/app/:appid/dash/:entryid',
        keepAlive: false,
    },
    {
        path: '/approCompleted',
        keepAlive: false,
    },
    {
        path: '/app/:appid/form/:formid',
        keepAlive: false,
    },
    {
        path: '/form/:formId/:mstId',
        keepAlive: false,
    },
    {
        path: '/process/:process/:incidentId/:taskId/:stepId/:status/:taskCreatorTime',
        keepAlive: false,
    },
    {
        path: '/processAppro/:process/:incidentId/:taskId/:stepId/:status/:taskCreatorTime/:taskUser',
        keepAlive: false,
    },
    {
        path: '/app/:appid/portal/:entryid',
        keepAlive: false,
    },
    {
        path: '/document-type/form-start/:documentTypeId',
        keepAlive: false,
    },
    {
        path: '/document-type/form/:documentTypeId/:mstId',
        keepAlive: false,
    },
    {
        path: '/Document/ImageAcquisition',
        keepAlive: false,
    },
    {
        path: '/Document/ReportDetail',
        keepAlive: false,
    },
    {
        path: '/application/index',
        keepAlive: false,
    },
    {
        path: '/EAA/archivesBookLoad',
        keepAlive: false,
    },
    {
        path: '/Archives/archivesIndex',
        keepAlive: false,
    },
    {
        path: '/EAA/alterBaOpePages',
        keepAlive: false,
    },
    {
        path: '/EAA/archivesCollect',
        keepAlive: false,
    },
    {
        path: '/EAA/imagePreview/:record',
        keepAlive: false,
    },
    {
        path: '/EAA/archivesFile',
        keepAlive: false,
    },
    {
        path: '/EAA/archivesFile/handoverList',
        keepAlive: false,
    },
    {
        path: '/EAA/BasicConfig',
        keepAlive: false,
    },
    {
        path: '/BorrowQuery/index',
        keepAlive: false,
    },
    {
        path: '/BorrowTotal/index',
        keepAlive: false,
    },
    {
        path: '/EAA/ComprehensiveQuery',
        keepAlive: false,
    },
    {
        path: '/EAA/destroy/application',
        keepAlive: false,
    },
    {
        path: '/EAA/destroy',
        keepAlive: false,
    },
    {
        path: '/EAA/destroy/detail',
        keepAlive: false,
    },
    {
        path: '/EAA/inventory',
        keepAlive: false,
    },
    {
        path: '/EAA/inventory/detail',
        keepAlive: false,
    },
    {
        path: '/EAA/DocumentQuery',
        keepAlive: false,
    },
    {
        path: '/FileTransfer/index',
        keepAlive: false,
    },
    {
        path: '/fulltext/index',
        keepAlive: false,
    },
    {
        path: '/interfaceLog/index',
        keepAlive: false,
    },
    {
        path: '/interfaceOther/index',
        keepAlive: false,
    },
    {
        path: '/cart/index',
        keepAlive: false,
    },
    {
        path: '/EAA/index',
        keepAlive: false,
    },
    {
        path: '/EAA/RecycleBin',
        keepAlive: false,
    },
    {
        path: '/returnRegistra/index',
        keepAlive: false,
    },
    {
        path: '/rulematching/index',
        keepAlive: false,
    },
    {
        path: '/eaa/shelf/await_query',
        keepAlive: false,
    },
    {
        path: '/shelf/index',
        keepAlive: false,
    },
    {
        path: '/eaa/test',
        keepAlive: false,
    },
    {
        path: '/voucherRules/index',
        keepAlive: false,
    },
    {
        path: '/warehouse/index',
        keepAlive: false,
    },
    {
        path: '/v2/warehouse/detail',
        keepAlive: false,
    },
    {
        path: '/warehouseDetail/index',
        keepAlive: false,
    },
    {
        path: '/fileView',
        keepAlive: false,
    },
    {
        path: '/flowcenter/CcUser/:data',
        keepAlive: false,
    },
    {
        path: '/flowcenter/create/:data',
        keepAlive: false,
    },
    {
        path: '/flowcenter/launch',
        keepAlive: false,
    },
    {
        path: '/flowcenter/todo/:data',
        keepAlive: false,
    },
    {
        path: '/flowcenter/transactors/:data',
        keepAlive: false,
    },
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/image/imagequery',
        keepAlive: false,
    },
    {
        path: '/login1',
        keepAlive: false,
    },
    {
        path: '/login2',
        keepAlive: false,
    },
    {
        path: '/forgetPassword',
        keepAlive: false,
    },
    {
        path: '/login/:clientName?/:userinfo?',
        keepAlive: false,
    },
    {
        path: '/notifications',
        keepAlive: false,
    },
    {
        path: '/thirdlogin',
        keepAlive: false,
    },
    {
        path: '/v2/category/config',
        keepAlive: false,
    },
    {
        path: '/v2/destroy/list',
        keepAlive: false,
    },
    {
        path: '/v2/destroy/index',
        keepAlive: false,
    },
    {
        path: '/v2/destroy/detail',
        keepAlive: false,
    },
    {
        path: '/v2/inventory/list',
        keepAlive: false,
    },
    {
        path: '/v2/inventory/plan',
        keepAlive: false,
    },
    {
        path: '/v2/archives/binding',
        keepAlive: false,
    },
    {
        path: '/v2/archives/collect',
        keepAlive: false,
    },
    {
        path: '/v2/archives/archive',
        keepAlive: false,
    },
    {
        path: '/v2/archives/filing',
        keepAlive: false,
    },
    {
        path: '/v2/archives/fv-config',
        keepAlive: false,
    },
    {
        path: '/v2/archives/off-shelft',
        keepAlive: false,
    },
    {
        path: '/v2/archives/operationlog',
        keepAlive: false,
    },
    {
        path: '/v2/archives/oraclesync',
        keepAlive: false,
    },
    {
        path: '/v2/archives/packFiling',
        keepAlive: false,
    },
    {
        path: '/v2/archives/printConfig',
        keepAlive: false,
    },
    {
        path: '/v2/archives/print',
        keepAlive: false,
    },
    {
        path: '/v2/archives/records',
        keepAlive: false,
    },
    {
        path: '/v2/archives/reimbursement',
        keepAlive: false,
    },
    {
        path: '/v2/archives/reshelft',
        keepAlive: false,
    },
    {
        path: '/v2/archives/review',
        keepAlive: false,
    },
    {
        path: '/v2/archives/shelft',
        keepAlive: false,
    },
    {
        path: '/v2/archives/transfer',
        keepAlive: false,
    },
    {
        path: '/v2/archives/transferlistold',
        keepAlive: false,
    },
    {
        path: '/v2/archives/transferlist',
        keepAlive: false,
    },
    {
        path: '/v2/archives/transferRecords',
        keepAlive: false,
    },
    {
        path: '/v2/accesst/template',
        keepAlive: false,
    },
    {
        path: '/v2/accesst/warehouse',
        keepAlive: false,
    },
    {
        path: '/v2/archives/archivesStatistic',
        keepAlive: false,
    },
    {
        path: '/v2/application/search',
        keepAlive: false,
    },
    {
        path: '/v2/archives/directoryQuery',
        keepAlive: false,
    },
    {
        path: '/v2/archives/application',
        keepAlive: false,
    },
    {
        path: '/v2/archives/reportConfig1',
        keepAlive: false,
    },
    {
        path: '/v2/archives/reportConfig',
        keepAlive: false,
    },
    {
        path: '/v2/archives/archivesIndex',
        keepAlive: false,
    },
    {
        path: '/v2/codeUpload',
        keepAlive: false,
    },
    {
        path: '/v2/archives/emailview/information',
        keepAlive: false,
    },
    {
        path: '/v2/archives/emailview',
        keepAlive: false,
    },
    {
        path: '/v2/docdetection/history',
        keepAlive: false,
    },
    {
        path: '/v2/docdetection/management',
        keepAlive: false,
    },
    {
        path: '/v2/editresearch/achievements',
        keepAlive: false,
    },
    {
        path: '/v2/editresearch/materials',
        keepAlive: false,
    },
    {
        path: '/v2/editresearch/view/:mstId',
        keepAlive: false,
    },
    {
        path: '/v2/imageQueryPreview',
        keepAlive: false,
    },
    {
        path: '/v2/imageQueryMobilePreview',
        keepAlive: false,
    },
    {
        path: '/v2/imagePreviewold',
        keepAlive: false,
    },
    {
        path: '/v2/imagePreviewfile',
        keepAlive: false,
    },
    {
        path: '/v2/imagePreview2',
        keepAlive: false,
    },
    {
        path: '/v2/imagePreviewview',
        keepAlive: false,
    },
    {
        path: '/v2/imagePreview',
        keepAlive: false,
    },
    {
        path: '/v2/archives/index',
        keepAlive: false,
    },
    {
        path: '/v2/singleLogin',
        keepAlive: false,
    },
    {
        path: '/v2/singleLogout',
        keepAlive: false,
    },
    {
        path: '/v2/system/microservice',
        keepAlive: false,
    },
    {
        path: '/v2/system/resource',
        keepAlive: false,
    },
    {
        path: '/v2/system/resource/server',
        keepAlive: false,
    },
    {
        path: '/v2/system/waring',
        keepAlive: false,
    },
    {
        path: '/v2/xbrl/fetch',
        keepAlive: false,
    },
    {
        path: '/v2/xbrl/record',
        keepAlive: false,
    },
    {
        path: '/workbench/workbench',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/app/:appid/dash/:entryid',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/app/dash-report/index.jsx'),
    },
    {
        path: '/approCompleted',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/app/form/approComple/processApproComple.jsx'),
    },
    {
        path: '/app/:appid/form/:formid',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/app/form/index.jsx'),
    },
    {
        path: '/form/:formId/:mstId',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/app/form/normal.jsx'),
    },
    {
        path: '/process/:process/:incidentId/:taskId/:stepId/:status/:taskCreatorTime',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/app/form/process.jsx'),
    },
    {
        path: '/processAppro/:process/:incidentId/:taskId/:stepId/:status/:taskCreatorTime/:taskUser',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/app/form/processAppro.jsx'),
    },
    {
        path: '/app/:appid/portal/:entryid',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/app/portal/index.jsx'),
    },
    {
        path: '/demo',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/demo/index.jsx'),
    },
    {
        path: '/document-type/form-start/:documentTypeId',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/Document/document-type-start.jsx'),
    },
    {
        path: '/document-type/form/:documentTypeId/:mstId',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/Document/document-type.jsx'),
    },
    {
        path: '/Document/ImageAcquisition',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/Document/ImageAcquisition.jsx'),
    },
    {
        path: '/Document/ReportDetail',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/Document/ReportDetail.jsx'),
    },
    {
        path: '/application/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/application/MyApplication.jsx'),
    },
    {
        path: '/EAA/archivesBookLoad',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/archivesBookloading/archivesBookLoad.jsx'),
    },
    {
        path: '/Archives/archivesIndex',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/archivesCards/archivesIndex.jsx'),
    },
    {
        path: '/EAA/alterBaOpePages',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/archivesCollect/alterBaOpePages.jsx'),
    },
    {
        path: '/EAA/archivesCollect',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/archivesCollect/archivesCollect.jsx'),
    },
    {
        path: '/EAA/imagePreview/:record',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/archivesCollect/ImagePreview.jsx'),
    },
    {
        path: '/EAA/archivesFile',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/archivesFile/archivesFile.jsx'),
    },
    {
        path: '/EAA/archivesFile/handoverList',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/archivesFile/handover-list.jsx'),
    },
    {
        path: '/EAA/BasicConfig',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/BasicConfig/BasicConfig.jsx'),
    },
    {
        path: '/BorrowQuery/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/BorrowQuery/BorrowQuery.jsx'),
    },
    {
        path: '/BorrowTotal/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/BorrowTotal/BorrowTotal.jsx'),
    },
    {
        path: '/EAA/ComprehensiveQuery',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/ComprehensiveQuery/ComprehensiveQuery.jsx'),
    },
    {
        path: '/EAA/destroy/application',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/destroy/container/application.jsx'),
    },
    {
        path: '/EAA/destroy',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/destroy/container/container.jsx'),
    },
    {
        path: '/EAA/destroy/detail',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/destroy/container/detail.jsx'),
    },
    {
        path: '/EAA/inventory',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/destroy/container/inventory.jsx'),
    },
    {
        path: '/EAA/inventory/detail',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/destroy/container/inventroy-detail.jsx'),
    },
    {
        path: '/EAA/DocumentQuery',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/DocumentQuery/DocumentQuery.jsx'),
    },
    {
        path: '/FileTransfer/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/fileTransfer/fileTransfer.jsx'),
    },
    {
        path: '/fulltext/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/fulltext/container.jsx'),
    },
    {
        path: '/interfaceLog/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/interfaceLog/index.jsx'),
    },
    {
        path: '/interfaceOther/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/interfaceOther/index.jsx'),
    },
    {
        path: '/cart/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/LendingLibrary/index.jsx'),
    },
    {
        path: '/EAA/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/portal/index.jsx'),
    },
    {
        path: '/EAA/RecycleBin',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/RecycleBin/RecycleBin.jsx'),
    },
    {
        path: '/returnRegistra/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/returnRegistra/returnRegistra.jsx'),
    },
    {
        path: '/rulematching/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/rulematching/index.jsx'),
    },
    {
        path: '/eaa/shelf/await_query',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/shelf/await-query.jsx'),
    },
    {
        path: '/shelf/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/shelf/index.jsx'),
    },
    {
        path: '/eaa/test',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/test.jsx'),
    },
    {
        path: '/voucherRules/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/voucherRules/index.jsx'),
    },
    {
        path: '/warehouse/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/warehouse/index.jsx'),
    },
    {
        path: '/v2/warehouse/detail',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/warehouse/warehouseDetail.jsx'),
    },
    {
        path: '/warehouseDetail/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/EAA/warehouseDetail/index.jsx'),
    },
    {
        path: '/app1/:data/form/:data',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/fileView',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/file-view/container.jsx'),
    },
    {
        path: '/flowcenter/CcUser/:data',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/flow-center/CcUser.jsx'),
    },
    {
        path: '/flowcenter/create/:data',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/flow-center/Create.jsx'),
    },
    {
        path: '/flowcenter/launch',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/flow-center/Launch.jsx'),
    },
    {
        path: '/flowcenter/todo/:data',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/flow-center/Todo.jsx'),
    },
    {
        path: '/flowcenter/transactors/:data',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/flow-center/Transactors.jsx'),
    },
    {
        path: '/',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/iframe/index.jsx'),
    },
    {
        path: '/image/imagequery',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/imagequery/container.jsx'),
    },
    {
        path: '/login1',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/login/index.jsx'),
    },
    {
        path: '/login2',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/login1/index.jsx'),
    },
    {
        path: '/menu-permission',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/forgetPassword',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/newLogin/forgetPassword.jsx'),
    },
    {
        path: '/login/:clientName?/:userinfo?',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/newLogin/index.jsx'),
    },
    {
        path: '/notifications',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/notifications/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/setting/index.jsx'),
    },
    {
        path: '/thirdlogin',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/third-login/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/users/Edit.jsx'),
    },
    {
        path: '/users',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/users/index.jsx'),
    },
    {
        path: '/personaldata',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/users/personalData.jsx'),
    },
    {
        path: '/v2/category/config',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-config/category-config/container.jsx'),
    },
    {
        path: '/v2/destroy/list',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-destroy/confirmDestroyContainer.jsx'),
    },
    {
        path: '/v2/destroy/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-destroy/container.jsx'),
    },
    {
        path: '/v2/destroy/detail',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-destroy/destroyDetail.jsx'),
    },
    {
        path: '/v2/inventory/list',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-inventory/list/container.jsx'),
    },
    {
        path: '/v2/inventory/plan',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-inventory/plan/container.jsx'),
    },
    {
        path: '/v2/archives/binding',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/BookBinding/container.jsx'),
    },
    {
        path: '/v2/archives/collect',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/collect/container.jsx'),
    },
    {
        path: '/v2/archives/archive',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/completionArchive/container.jsx'),
    },
    {
        path: '/v2/archives/filing',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/filing/container.jsx'),
    },
    {
        path: '/v2/archives/fv-config',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/finance-voucherConfig/index.jsx'),
    },
    {
        path: '/v2/archives/off-shelft',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/off-shelft/index.jsx'),
    },
    {
        path: '/v2/archives/operationlog',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/operation-log/index.jsx'),
    },
    {
        path: '/v2/archives/oraclesync',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/oraclesync/index.jsx'),
    },
    {
        path: '/v2/archives/packFiling',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/packFiling/container.jsx'),
    },
    {
        path: '/v2/archives/printConfig',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/print-config/container.jsx'),
    },
    {
        path: '/v2/archives/print',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/print/container.jsx'),
    },
    {
        path: '/v2/archives/records',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/records/container.jsx'),
    },
    {
        path: '/v2/archives/reimbursement',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/reimbursement/container.jsx'),
    },
    {
        path: '/v2/archives/reshelft',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/reShelft/container.jsx'),
    },
    {
        path: '/v2/archives/review',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/review/container.jsx'),
    },
    {
        path: '/v2/archives/shelft',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/shelft/container.jsx'),
    },
    {
        path: '/v2/archives/transfer',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/transfer/container.jsx'),
    },
    {
        path: '/v2/archives/transferlistold',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/transferlis/container.jsx'),
    },
    {
        path: '/v2/archives/transferlist',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/transferlis/index.jsx'),
    },
    {
        path: '/v2/archives/transferRecords',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-management/transferRecords/container.jsx'),
    },
    {
        path: '/v2/accesst/template',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/access-template/index.jsx'),
    },
    {
        path: '/v2/accesst/warehouse',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/access-warehouse/index.jsx'),
    },
    {
        path: '/v2/archives/archivesStatistic',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/archivesStatistics/Container.jsx'),
    },
    {
        path: '/v2/application/search',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/borrowSearch/container.jsx'),
    },
    {
        path: '/v2/archives/directoryQuery',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/directory-query/container.jsx'),
    },
    {
        path: '/v2/archives/application',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/myBorrow/Container.jsx'),
    },
    {
        path: '/v2/archives/reportConfig1',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/report-config/container.jsx'),
    },
    {
        path: '/v2/archives/reportConfig',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archives-use/reportConfig/container.jsx'),
    },
    {
        path: '/v2/archives/archivesIndex',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/archivesStore/StorageRoom/container.jsx'),
    },
    {
        path: '/v2/codeUpload',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/code-upload/container.jsx'),
    },
    {
        path: '/v2/archives/emailview/information',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/commons/archive-information/container.jsx'),
    },
    {
        path: '/v2/archives/emailview',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/commons/email-archives-view/container.jsx'),
    },
    {
        path: '/v2/docdetection/history',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-detection/historys/index.jsx'),
    },
    {
        path: '/v2/docdetection/management',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-detection/management/index.jsx'),
    },
    {
        path: '/v2/editresearch/achievements',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-editresearch/achievements/index.jsx'),
    },
    {
        path: '/v2/editresearch/materials',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-editresearch/materials/index.jsx'),
    },
    {
        path: '/v2/editresearch/view/:mstId',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-editresearch/view/index.jsx'),
    },
    {
        path: '/v2/imageQueryPreview',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-imagepreview/container.jsx'),
    },
    {
        path: '/v2/imageQueryMobilePreview',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-mobilepreview/container.jsx'),
    },
    {
        path: '/v2/imagePreviewold',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-preview/container.jsx'),
    },
    {
        path: '/v2/imagePreviewfile',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-previewimg/container.jsx'),
    },
    {
        path: '/v2/imagePreview2',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-previewnew/container.jsx'),
    },
    {
        path: '/v2/imagePreviewview',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-view/container.jsx'),
    },
    {
        path: '/v2/imagePreview',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/document-viewnew/container.jsx'),
    },
    {
        path: '/v2/archives/index',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/portal/container.jsx'),
    },
    {
        path: '/v2/singleLogin',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/single-login/container.jsx'),
    },
    {
        path: '/v2/singleLogout',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/single-logout/container.jsx'),
    },
    {
        path: '/v2/system/microservice',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/system-management/microservice/container.jsx'),
    },
    {
        path: '/v2/system/resource',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/system-management/resource/container.jsx'),
    },
    {
        path: '/v2/system/resource/server',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/system-management/server/container.jsx'),
    },
    {
        path: '/v2/system/waring',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/system-management/warning/contsiner.jsx'),
    },
    {
        path: '/v2/xbrl/fetch',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/xbrl/container.jsx'),
    },
    {
        path: '/v2/xbrl/record',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/V2/views/xbrl/recordContainer.jsx'),
    },
    {
        path: '/workbench/workbench',
        component: () => import('/root/workspace/DocparkV2_Docpark-App_SeTx/src/pages/workbench/containerr.jsx'),
    },
];
