import {sxAjax} from '@/commons/ajax';

export default{
    namespace:'eaaOrganization',
    initialState:{
        companyData:[],
        departmentData:[]
    },
    getCompanylist:{
        payload:(params)=>sxAjax.get('api/services/app/OrganizationUnit/GetCompanyList',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={companyData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getDepartment:{
        payload:(params)=>sxAjax.get('api/services/app/OrganizationUnit/GetDepartment',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={departmentData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
}
