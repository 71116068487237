import {sxAjax} from '@/commons/ajax';

export default{
    namespace:'Portal',
    initialState:{
        Statistics:{},
        BorrowData:[],//借阅
        ReturnData:[],//归还
        CollectionData:[],//统计图
        CategoryData:[],//文件类型统计
        ApplicationData:[],//申请统计
        MyApplyData:[],
    },
    getStatistics:{
        payload:(params)=>sxAjax.get('api/services/app/EaaPortal/GetFileStatistics',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={Statistics:payload.result.data};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getBorrow:{
        payload:(params)=>sxAjax.post('api/services/app/EaaApplicationProcess/GetMyApplicationPageList',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={BorrowData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getReturn:{
        payload:(params)=>sxAjax.post('api/services/app/EaaApplicationProcess/GetApplicationPageList',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={ReturnData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getCollectionStatistics:{
        payload:(params)=>sxAjax.get('api/services/app/EaaPortal/GetFileCollectionStatistics',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={CollectionData:payload.result.data};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getFileCategoryStatistics:{
        payload:(params)=>sxAjax.get('api/services/app/EaaPortal/GetFileCategoryStatistics',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={CategoryData:payload.result.data};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getApplicationStatistics:{
        payload:(params)=>sxAjax.get('api/services/app/Portal/GetApplicationStatistics',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={ApplicationData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getApplyStatistics:{
        payload:(params)=>sxAjax.get('api/services/app/Portal/GetApplyStatistics',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={MyApplyData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    }
}