import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
import axios from 'axios';
declare var abp: any;
export default{
    namespace: 'archiveColl',
    initialState:{
        BatchOVisible: false,//批量操作弹窗隐藏显示
        CreateModVisible:false,//创建操作弹窗隐藏显示
        UpdateModVisible:false,//修改操作弹窗隐藏显示
        showFooter:true,//显示弹出层的修改按钮
        DocPreviewVisible:false,//附件预览隐藏显示
    },
    /**组件卸载 */
    setUnmount: () => {
        return {
            BatchOVisible: false,//批量操作弹窗隐藏显示
            CreateModVisible:false,//创建操作弹窗隐藏显示
            UpdateModVisible:false,//修改操作弹窗隐藏显示
        }
    },
    setBatchOVisible: (BatchOVisible, _) => {
        return { BatchOVisible };
    },
    setCreateModVisible: (CreateModVisible, _) => {
        return { CreateModVisible };
    },
    setUpdateModVisible: (UpdateModVisible, _) => {
        return { UpdateModVisible };
    },
    setShowFooter: (showFooter, _) => {
        return { showFooter };
    },
    setDocPreviewVisible: (DocPreviewVisible, _) => {
        return { DocPreviewVisible };
    },
}