import { createModel } from "hox";
import { useImmer } from "use-immer";

const initialValue = {
    lang: "zh",
};

const useApp = () => {
    const [appInfo, setAppInfo] = useImmer(initialValue);

    const getAppInfoByKey = (key) => {
        return appInfo[key];
    };

    const updateAppInfoByKey = (key, value) => {
        return setAppInfo((draft) => {
            draft[key] = value;
        });
    };

    return { getAppInfoByKey, updateAppInfoByKey };
};

export default createModel(useApp);
