import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import AppRouter from "./router/AppRouter";
import { connect } from "./models";
import moment from "moment";
import "moment/locale/zh-cn"; // 解决 antd 日期组件国际化问题
import SignalRAspNetCoreHelper from "@/lib/SignalRAspNetCoreHelper";
import { isLogin } from "@/commons/";
import { IntlProvider } from "react-intl";
import message from "@/lang";
import useApp from "@/store";
import { withModel } from "hox";

///解决IE兼容问题
import Promise from "promise-polyfill";
import "babel-polyfill";

declare var abp: any;

// To add to window  解决promise 在ie中未定义的问题
if (!window.Promise) {
    window.Promise = Promise;
}

// 设置语言
moment.locale("zh-cn");

const App = function () {
    const { getAppInfoByKey } = useApp();
    const lang = getAppInfoByKey("lang");

    const locales = {
        zh: "zh_CN",
        en: "en_US",
    };

    useEffect(() => {
        if (isLogin()) {
            SignalRAspNetCoreHelper.initSignalR();
        }
    }, []);

    return (
        <IntlProvider locale={lang} messages={message[locales[lang]]}>
            <ConfigProvider locale={abp.localization.currentLanguage.name === 'en' ? '' : zhCN}>
                <AppRouter />
            </ConfigProvider>
        </IntlProvider>
    );
};

export default connect()(App);

// // @connect()
// class App extends React.Component {
//     constructor(props) {}

//     componentDidMount() {
//         if (isLogin()) {
//             SignalRAspNetCoreHelper.initSignalR();
//         }
//         console.log("props", this.props);
//     }

//     render() {
//         return (
//             <IntlProvider locale="zh" messages={message["zh_CN"]}>
//                 <ConfigProvider locale={zhCN}>
//                     <AppRouter />
//                 </ConfigProvider>
//             </IntlProvider>
//         );
//     }
// }

// export default withModel(useApp, )(connect()(App));
