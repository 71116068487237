import { sxAjax } from '@/commons/ajax';

export default {
    namespace: 'user',
    initialState: {
        userData: [],
        listLoading: false,
        loading: false
    },
    getUsers: {
        payload: (params) => sxAjax.get('api/services/app/User/GetUsers', params),
        reducer: {
            pending: (state, action) => ({ listLoading: true }),
            resolve(state, { payload = {} }) {
                return {
                    userData: payload.result
                }
            },
            complete: (state, action) => ({ listLoading: false }),
        }
    },
    getUsersByUserNames: {
        payload: (params) => sxAjax.post('api/services/app/User/GetUsersByUserNames', params),
        reducer: {
            pending: (state, action) => ({ listLoading: true }),
            resolve(state, { payload = {} }) {
                return {
                    userData: payload.result
                }
            },
            complete: (state, action) => ({ listLoading: false }),
        }
    },
    getUsersByUserIds: {
        payload: (params) => sxAjax.post('api/services/app/User/GetUsersByUserIds', params),
        reducer: {
            pending: (state, action) => ({ listLoading: true }),
            resolve(state, { payload = {} }) {
                return {
                    userData: payload.result
                }
            },
            complete: (state, action) => ({ listLoading: false }),
        }
    },
    createOrUpdateUser: {
        payload: (params) => sxAjax.post('api/services/app/User/CreateOrUpdateUser', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    deleteUser: {
        payload: (params) => sxAjax.del('api/services/app/User/DeleteUser', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    importUsersFromFile: {
        payload: (params) => sxAjax.post('api/services/app/User/ImportUsersFromFile', params),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {

            },
            complete: (state, action) => ({ loading: false }),
        }
    }
}