import { useState } from 'react';
import { createModel } from 'hox';

function usePackFiling() {
    const [bindingRules, setBindingRules] = useState(0);
    return {
        bindingRules,
        setBindingRules,
    };
}

export default createModel(usePackFiling);