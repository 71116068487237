import { sxAjax } from "@/commons/ajax";
declare var abp: any;
export default {
    namespace: "agent",
    initialState: {
        loading: false,
        agentData: {},
        historyData: {},
    },

    onChangeFilesEmailAddress: (fileValue, state) => {
        let { user } = state;
        user.emailAddress = fileValue;
        return { user };
    },
    getAgentList: {
        //获取用户信息
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") + "agent/list",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return { agentData: payload?.result };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    setEnabled: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") +
                    "agent/setEnabled",
                params,
                { successTip: "处理成功" }
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    delAgent: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") + "agent/Del",
                params,
                { successTip: "删除成功" }
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    addAgent: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") + "agent/Add",
                params,
                { successTip: "新建成功" }
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    upAgent: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") +
                    "agent/update",
                params,
                { successTip: "处理成功" }
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    getAgentHistoryList: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") +
                    "agent/history/list",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return { historyData: payload?.result };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    addAgentHistory: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") +
                    "agent/history/add",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
    clearAgentHistory: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.DocumentService.Host") +
                    "agent/history/AllAsRead",
                params,
                { successTip: "处理成功" }
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
};
