import { sxAjax } from '@/commons/ajax';
declare var abp: any;
export default {
    namespace: 'comment',
    initialState: {
        commentList: [],
        loading: false,
        comment: "",
    },
    setUnmount: () => {
        return {
            commentList: [],
            loading: false,
            comment: "",
        }
    },
    setComment: (value, state) => {
        return { comment: value };
    },
    getCommentList: {
        payload: (params) => {
            return sxAjax.get('api/services/app/Comment/GetComments', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const data = payload.result;
                return ({ commentList: data });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    createComment: {
        payload: (params) => {
            return sxAjax.post('api/services/app/Comment/Create', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {}
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    }
}