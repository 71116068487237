import React from 'react';
import { createModel } from 'hox';
import { message } from 'antd'
import { L } from '@/lib/abpUtility';
import { getLoginUser } from "@/commons";


declare var abp: any;

const modal = () => {

    //扫描设备
    const [productSources, setProductSources] = React.useState(null);
    //打印设备
    const [printSources, setPrintSources] = React.useState(null);
    //返回的数据
    const [receiverMessage, setReceiverMessage] = React.useState({});
    /**
     * webSocket初始化
     */
    const socket = React.useRef(null);
    React.useLayoutEffect(() => {
        console.log('webSocket init');
        if (!socket.current || socket.current.readyState === 3) {
            socket.current = new WebSocket('ws://127.0.0.1:51666');

            socket.current.onopen = (_e) => {
                console.log('webSocket sucess');
            };

            socket.current.onclose = (_e) => {
                console.log('websocket close');
            };

            socket.current.onerror = (e) => {
                console.log('websocket error', e);
                //message.error(L('扫描服务连接失败，请确认扫描服务正常开启. 如已开启服务请重新刷新界面'));
            };

            socket.current.onmessage = async (msg) => {
                console.log('socket receiver message', msg.data);
                webSocketReceiverMessage(JSON.parse(msg.data));
            };
        }


        return () => {
            socket.current && socket.current.close();
        };
    }, [socket]);

    /**
     * webSocket发送消息
     * @param {*} message
     * @returns
     */
    const webSocketSendMessage = (msg) => {
        if (socket.current?.readyState !== 1) {
            console.log('尚未链接成功');
            message.error(L('扫描服务连接失败，请确认扫描服务正常开启. 如已开启服务请重新刷新界面'));
            return;
        }
        console.log('socket send message', msg);
        socket.current.send(msg);

        // //test
        // if (msg.indexOf('Acquire') === 0) {
        //     setTimeout(() => {
        //         webSocketReceiverMessage(test);
        //     }, 2000);
        // }
    };

    /**
     * webSocket接受消息
     * @param {*} msg
     */
    const webSocketReceiverMessage = (msg) => {
        if (msg.Command === "GetSources") {
            msg.ErrorCode === 0 && setProductSources([...msg?.Sources]);
        } else if (msg.Command === "GetPrintSources") {
            msg.ErrorCode === 0 && setPrintSources([...msg?.Sources]);
        } else {
            setReceiverMessage(msg);
        }
    };
    /**
     * 获取扫描驱动
     */
    const onGetSources = () => {
        webSocketSendMessage('GetSources');
    }
    /**
     * 获取打印驱动
     */
    const onGetPrintSources = () => {
        webSocketSendMessage('GetPrintSources');
    }
    /**
     * 扫描
     * @param {扫描驱动} productName 
     * @param {切割类型} separationType 
     * @param {businessTypeId} businessTypeId 
     * @param {扫描模板} templateParam 
     */
    const onAcquire = (productName, separationType, businessTypeId, templateParam) => {
        const { token } = getLoginUser();
        const data = {
            ...{
                productName,
                separationType,
                businessTypeId,
                documentServiceUrl: abp.setting.get('App.Api.DocumentService.Host'),
                token: `Bearer ${token}`,
            },
            ...templateParam
        };
        webSocketSendMessage('Acquire ' + JSON.stringify(data));
    }
    /**
     * 批量打印
     * @param {打印驱动} printerName 
     * @param {彩色/黑白} color 
     * @param {文档ID数组} docIds 
     * @param {文档打印名称，可为空} names 
     */
    const onBatchPrint = (printerName, color, docIds, names) => {
        const { token } = getLoginUser();
        const data = {
            printerName: printerName,
            color: color,
            docIds: docIds?.length ? docIds : [],
            names: names?.length ? names : [],
            ftpHost: abp.setting.get('App.Api.FTP.Host'),
            ofdHost: abp.setting.get('App.Api.OfdSvc.Host'),
            token: `Bearer ${token}`,
        };

        webSocketSendMessage('BatchPrint ' + JSON.stringify(data));
    }

    return {
        productSources,
        printSources,
        receiverMessage,
        setReceiverMessage,
        onGetSources,
        onGetPrintSources,
        onAcquire,
        onBatchPrint,
    };
};

export default createModel(modal);

