import {sxAjax} from '@/commons/ajax';

export default{
    namespace: 'menus',
    initialState:{
        menus:[],
        loading:false,
    },
    getNavigationMenus:{
        payload:()=>sxAjax.get('api/services/app/NaviagationAppServices/GetNaviagationMenus'),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                return {
                    menus: payload.result,
                };
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    }
}