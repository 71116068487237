import { sxAjax } from "@/commons/ajax";
declare var abp: any;
export default {
    namespace: "eaaFile",
    initialState: {
        formId: "1ebe2eff-b1f7-fbcd-4b5b-dffd94fe0fee",
        formName: "文件主数据表",
        fields: [],
        dataSource: [],
        totalCount: 0,
        deleteFiles: {},
        loading: false,
        //日志
        logFormId: "ecff17ef-90d6-15cf-2b3a-13fa5ebaedfc",
        logFormName: "文件日志表",
        logFields: [],
        logData: {},
        logLoading: false,
    },
    setUnmount: () => {
        return {
            formId: "1ebe2eff-b1f7-fbcd-4b5b-dffd94fe0fee",
            formName: "文件主数据表",
            fields: [],
            dataSource: [],
            totalCount: 0,
            deleteFiles: {},
            loading: false,
            //日志
            logFormId: "ecff17ef-90d6-15cf-2b3a-13fa5ebaedfc",
            logFormName: "文件日志表",
            logFields: [],
            logData: {},
        };
    },
    setDataSource: (dataSource, _) => {
        return { dataSource };
    },
    getFormFields: {
        payload: (params) => {
            return sxAjax.get(
                "api/services/app/AppObject/GetFromFields?formId=" +
                    params.formId
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const fields = payload.result;
                return { fields };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getLogFields: {
        payload: (params) => {
            return sxAjax.get(
                "api/services/app/AppObject/GetFromFields?formId=" +
                    params.formId
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const fields = payload.result;
                return { logFields: fields };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    GetFormFiles: {
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/AppForm/GetFormAllData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const dataSource = payload?.result?.items || [];
                const totalCount = payload?.result?.totalCount || 0;
                return { dataSource, totalCount };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    GetDeleteFiles: {
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/AppForm/GetFormAllData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const data = payload?.result;
                return { deleteFiles: data };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    GetFormLogs: {
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/AppForm/GetFormAllData",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ logLoading: true }),
            resolve(state, { payload = {} }) {
                const data = payload?.result;
                return { logData: data };
            },
            complete: (state, action) => {
                return { logLoading: false };
            },
        },
    },
    DownloadDocuments: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.FTP.Host") + "api/FTP/Down",
                params,
                { responseType: "blob" }
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var blob = new Blob([payload], {
                    type: "application/octet-stream",
                });
                var downloadElement = document.createElement("a");
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download = "影像文件压缩包.zip"; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    SaveLogBatch: {
        payload: (params) => {
            return sxAjax.post(
                "api/services/app/AppObject/SaveFormDataBatch",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
};
