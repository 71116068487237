import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
import axios from 'axios';
import { getLoginUser } from '@/commons';
declare var abp: any;
export default {
    namespace: 'archivesList',
    initialState: {
        DataList: [],//第一层级档案室 数据
        HouseData: [],//第二层级柜  数据

        InitialDisplay: true,//默认显示空 用来判断显示哪个层级
        InitialTable: 'none',//表格属性,默认不显示
        loading: true,
        searchList: [],//列表设计-搜索选中标签
        TableAllColumn: [],//列表所有字段,
        Showstable: [],//列表设计-表格选中标签-展现的表头,
        imageListData: [],//列表表格数据源
        imageListDataCount: 0,//列表数据总数
        searchFillInData: {//搜索条件值 展现搜索条件列表或表格表头
            FormId: '',
            Filters: [
            ],
            Pager:{}
        },
        SourcesData: '',//数据来源字段名
        FileTypeField: '',// 
        MstId: '',
        FileType: '',//档案类型
        fields: [],//表单所有数据库字段
        PositionField: '',//位置名
        PositionKeysField: '',//位置keys
        Status:'',//状态
        ArchivesKeys:''//档案室Key组
    },
    /**组件卸载 */
    setUnmount: () => {
        return {
            DataList: [],//第一层级档案室 数据
            HouseData: [],//第二层级柜  数据
    
            InitialDisplay: true,//默认显示空 用来判断显示哪个层级
            InitialTable: 'none',//表格属性,默认不显示
            loading: true,
            searchList: [],//列表设计-搜索选中标签
            TableAllColumn: [],//列表所有字段,
            Showstable: [],//列表设计-表格选中标签-展现的表头,
            imageListData: [],//列表表格数据源
            imageListDataCount: 0,//列表数据总数
            searchFillInData: {//搜索条件值 展现搜索条件列表或表格表头
                FormId: '',
                Filters: [
                ],
                Pager:{}
            },
            SourcesData: '',//数据来源字段名
            FileTypeField: '',// 
            MstId: '',
            FileType: '',//档案类型
            fields: [],//表单所有数据库字段
            PositionField: '',//位置名
            PositionKeysField: '',//位置keys
            Status:'',//状态
            ArchivesKeys:''//档案室Key组

        }
    },
    setHouseData: (HouseData, _) => {
        return { HouseData };
    },
    setDataList: (DataList, _) => {
        return { DataList };
    },
    setInitialDisplay: (InitialDisplay, _) => {
        return { InitialDisplay };
    },
    setInitialTable: (InitialTable, _) => {
        return { InitialTable };
    },

    getFirstCard: {

        payload: (params) => {
            return sxAjax.get('/api/services/app/ArchivesCards/GetFirstCardsData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let DataList = payload.result;
                return ({ DataList: DataList });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },


    getSecondCard: {//获取列表数据
        payload: (params) => {
            return sxAjax.get('api/services/app/ArchivesCards/GetSecondCardsData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let HouseData = payload.result;
                return ({ HouseData: HouseData });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },


 
    getForm: {
        payload: (params) => {
            return sxAjax.get('api/services/app/AppObject/GetForm?formId=' + params.FormId);
            ///api/services/app/DocumentForm/GetFields?documentTypeId=60541597f7a4e56f7271e005
            //return sxAjax.get('api/services/app/AppObject/GetForm?formId=' + params.FormId);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { formId, formName, formType, data, properties, fields, listDesignAttrs } = payload.result;
                let Showstable = [];
                let TableAllColumn = [];
                let CompanyNameField = payload.result.fields.find(function (value) {//公司、

                    return value.customType === "CompanyName"
                });

                let FileNumberField = payload.result.fields.find(function (value) {//档案号、

                    return value.customType === "FileNumber"

                });
                let CaseNoField = payload.result.fields.find(function (value) {//案卷号、

                    return value.customType === "CaseNo"
                });
                let FileTypeField = payload.result.fields.find(function (value) {//档案类型、
                    return value.customType === "FileType"
                });
                let TitleField = payload.result.fields.find(function (value) {//标题、

                    return value.customType === "Title"
                });
                let PositionField = payload.result.fields.find(function (value) {//位置、

                    return value.customType === "Position"
                });
             
                
                let DocTypeField = payload.result.fields.find(function (value) {//文件类型。

                    return value.customType === "DocType"
                });
                let PositionKeysField = payload.result.fields.find(function (value) {//位置key、

                    return value.customType === "PositionKeys"
                });
                let StatusField=payload.result.fields.find(function (value) {//位置key、

                    return value.identity=="Status"
                });
                let ArchivesKeysField=payload.result.fields.find(function (value) {//位置key、

                    return value.identity=="ArchivesKeys"
                });

                if (listDesignAttrs != null) {
                    if (listDesignAttrs.tableChecks != null) {
                        for (let i = 0; i < listDesignAttrs.tableChecks.length; i++) {
                            listDesignAttrs.tableChecks[i].dataIndex = listDesignAttrs.tableChecks[i].dataIndex.replace('-', '_');
                            listDesignAttrs.tableChecks[i].key = listDesignAttrs.tableChecks[i].key.replace('-', '_');
                            if (listDesignAttrs.tableChecks[i].key == CompanyNameField.id.replace('-', '_') ||
                                listDesignAttrs.tableChecks[i].key == FileNumberField.id.replace('-', '_') ||
                                listDesignAttrs.tableChecks[i].key == CaseNoField.id.replace('-', '_') ||
                                listDesignAttrs.tableChecks[i].key == FileTypeField.id.replace('-', '_') ||
                                listDesignAttrs.tableChecks[i].key == TitleField.id.replace('-', '_') ||
                                listDesignAttrs.tableChecks[i].key == PositionField.id.replace('-', '_') ||
                                listDesignAttrs.tableChecks[i].key == DocTypeField.id.replace('-', '_') 
                                
                                ) {
                                Showstable.push(listDesignAttrs.tableChecks[i])
                                TableAllColumn.push(listDesignAttrs.tableChecks[i])
                            } else {
                                TableAllColumn.push(listDesignAttrs.tableChecks[i])
                            }
                        }
                    }
                    if (listDesignAttrs.searchList != null) {
                        for (let i = 0; i < listDesignAttrs.searchList.length; i++) {
                            listDesignAttrs.searchList[i].value = listDesignAttrs.searchList[i].value.replace('-', '_')
                        }
                    }
                }
                let result = {
                    Showstable, TableAllColumn, CompanyNameField: CompanyNameField?.id, formId, fields,
                    FileNumberField: FileNumberField?.id,
                    CaseNoField: CaseNoField?.id, 
                    FileTypeField: FileTypeField?.id,
                    TitleField: TitleField?.id,PositionField: PositionField?.id,
                    DocTypeField: DocTypeField?.id,PositionKeysField:PositionKeysField?.id,
                    Status: StatusField?.id,ArchivesKeys:ArchivesKeysField?.id
                    // searchList: listDesignAttrs != null ? (listDesignAttrs.searchLists != null ? listDesignAttrs.searchLists : []) : [],
                };
                if (properties) {
                    result.properties = properties;
                }
                return (result);
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getListData: {//获取列表数据
  
        payload: (params) => { 
            return sxAjax.post('api/services/app/ArchivesService/TableListData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imageListData = payload.result;
                const imageListDataCount = payload.result.length
                return ({ imageListData: imageListData, imageListDataCount });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },


}

