const en_US = {
    query: "Query",
    reset: "Reset",
    addCart: "Add cart",
    addSuccess: "Add success",
    addFail: "Add fail",
    home: "Home",
    dicQuery: "Directory query",
    position: "Position",
    docType: "Document type",
    fileLevel: "File level",
    EAF: "Electronic Accounting File",
    enterBackManagement: "Enter background management",
    templatePrintConf: "Template printing configuration",
    documentType: "Document type",
    epidermis: "Epidermis",
    spine: "Spine",
    save: "Save",
    totalItems: "Total {total} items",
    book: "Book",
    offBook: "Off-book",
    saveSuccess: "Save success",
    saveFail: "Save fail",
    reportConfig: "Report config",
    form: "Form",
    name: "Name",
    metadataField: "Metadata field",
    fileCollect: "File collect",
    fieldConfig: "Field configuration",
    confirm: "Confirm",
    cancel: "Cancel",
    fileBook: "File book",
    archive: "Archive",
    fileTransfer: "File transfer",
    fileReShelf: "waiting shelft",
    fileShelf: "File shelf",
    printList: "Print List",
    operation: "Operation",
    formName: "Form name",
    id: "ID",
    updateFieldName: "Update field name",
    delSuccess: "Delete success",
    delFail: "Delete fail",
    modifyFilterCondition: "Modify filter condition",
};

export default en_US;
