import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import { renderNode } from "@/library/utils/tree-utils";
import Link from "../page-link";
import { L, isGranted } from "../../lib/abpUtility";
import "./style.less";

const SubMenu = Menu.SubMenu;
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3839748_cq4afahd16.js",
});
export default class SideMenu extends Component {
    static propTypes = {
        dataSource: PropTypes.array, // 菜单数据
        theme: PropTypes.string, // 主题
        collapsed: PropTypes.bool, // 是否收起
        openKeys: PropTypes.array, // 打开菜单keys
        selectedKeys: PropTypes.array, // 选中菜单keys
        onOpenChange: PropTypes.func, // 菜单打开关闭时触发
    };

    static defaultProps = {
        dataSource: [],
        theme: "dark",
        collapsed: false,
        openKeys: [],
        selectedKeys: [],
        onOpenChange: () => true,
    };

    handleOpenChange = (openKeys) => {
        this.props.onOpenChange(openKeys);
    };
    menuIconFun = () => {
        
    }

    renderMenus() {
        const { dataSource } = this.props;
        if (dataSource && dataSource.length) {
            // 删除借阅申请单和销毁
            const menuData = [...dataSource].filter(
                (item) => item?.key !== "c524223e-d160-9c55-fefb-b15e2fcdbe43" && item?.key !== "23ff8ab6-c2d7-3d1f-c6e5-957749ab03bb"
            );

            return renderNode(menuData, (item, children) => {
                const { key, path, text, icon, target, url } = item;
                
                let title = <span>{L(text)}</span>;
                if (icon)
                    title = (
                        <span className={text === '个人门户' ? 'topCss' : ''}>
                            <Icon type={icon} />
                            {/* {text == '个人门户' ? <Icon type={icon} /> : ''} */}
                            {/* <Icon type="home" style={{ display: "none" }} /> */}
                            <span>{L(text)}</span>
                        </span>
                    );

                if (children) {
                    title = (
                        <span>
                            <Icon type="home" style={{ display: "none" }} />
                            {(text === '档案保管') ? (<>
                                <IconFont type='icon-case-file-full' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '档案整理') ? (<>
                                <IconFont type='icon-zhengli' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '资料接收') ? (<>
                                <IconFont type='icon-guidang' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '系统管理') ? (<>
                                <IconFont type='icon-setting' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '系统监控') ? (<>
                                <IconFont type='icon-jiankong' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '档案盘点') ? (<>
                                <IconFont type='icon-kucunpandian' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '档案利用' ) ? (<>
                                <IconFont type='icon-danganliyong' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '日志管理' ) ? (<>
                                <IconFont type='icon-rizhiguanli' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '档案编研' ) ? (<>
                                <IconFont type='icon-danganbianyan' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '档案鉴定' ) ? (<>
                                <IconFont type='icon-wj-jdws' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text === '档案检索' ) ? (<>
                                <IconFont type='icon-mc-xzjs' style={{fontSize:'16px'}}/>
                                <span>{L(text)}</span>
                                </>): null}
                            {(text !== '资料接收') && (text !== '档案保管') && (text !== '系统管理') && 
                             (text !== '日志管理') && (text !== '档案盘点') && (text !== '档案利用') &&
                             (text !== '档案编研') && (text !== '档案鉴定') && (text !== '档案检索') &&
                             (text !== '系统监控') && (text !== '档案整理')
                              && (<>
                                <Icon type={"folder"} />
                                <span>{L(text)}</span>
                            </>)
                            }
                        </span>
                    );
                    return (
                        <SubMenu key={key} title={title}>
                            {children}
                        </SubMenu>
                    );
                }
                if (item.permission && !isGranted(item.permission)) return null;
                return (
                    <Menu.Item
                        style={{
                            margin: "0 auto",
                            padding: "0 auto",
                            height: "44px",
                            lineHeight: "44px",
                        }}
                        key={key}
                    >
                        {target ? (
                            <a href={url} target={target}>
                                {title}
                            </a>
                        ) : (
                            <Link
                                to={{
                                    pathname: path,
                                    state: { from: "menu" },
                                }}
                            >
                                {title}
                            </Link>
                        )}
                    </Menu.Item>
                );
            });
        }
        return null;
    }

    render() {
        let { theme, collapsed, openKeys, selectedKeys } = this.props;
        const menuProps = collapsed
            ? {}
            : {
                openKeys,
            };
        return (
            <div styleName="side-menu">
                <Menu
                    {...menuProps}
                    selectedKeys={selectedKeys}
                    mode="inline"
                    theme={theme}
                    inlineCollapsed={collapsed}
                    onOpenChange={this.handleOpenChange}
                >
                    {this.renderMenus()}
                </Menu>
            </div>
        );
    }
}
