import React, { Component } from "react";
import { Dropdown, Icon, Menu } from "antd";
import { L } from "../../lib/abpUtility";
import classNames from "classnames";
import "./index.less";
import { sxAjax } from "@/commons/ajax";
import useStore from "@/store";
import { withModel } from "hox";

declare var abp: any;

class LanguageSelect extends Component {
    get languages() {
        return abp.localization.languages.filter((val) => {
            return !val.isDisabled;
        });
    }

    async changeLanguage(languageName) {
        //await this.props.userStore!.changeLanguage(languageName);
        sxAjax
            .post("/api/services/app/Profile/ChangeLanguage", {
                languageName: languageName,
            })
            .then((res) => {
                abp.utils.setCookieValue(
                    "Abp.Localization.CultureName",
                    languageName,
                    new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                    abp.appPath
                );

                window.location.reload();
            });
    }
    get currentLanguage() {
        return abp.localization.currentLanguage;
    }

    render() {
        const langMenu = (
            <Menu
                className={"menu"}
                selectedKeys={[this.currentLanguage.name]}
                onClick={(info) => {
                    switch (info.key) {
                        case "zh-Hans":
                            this.props.updateAppInfoByKey("lang", "zh");
                            break;
                        case "en":
                            this.props.updateAppInfoByKey("lang", "en");
                            break;
                        default:
                            this.props.updateAppInfoByKey("lang", "zh");
                            break;
                    }
                }}
            >
                {this.languages.map((item) => (
                    <Menu.Item
                        key={item.name}
                        onClick={() => this.changeLanguage(item.name)}
                    >
                        <i className={item.icon} /> {item.displayName}
                    </Menu.Item>
                ))}
            </Menu>
        );
        const { className } = this.props;
        return (
            <div className={className}>
                <Dropdown overlay={langMenu} placement="bottomRight">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "30px",
                            lineHeight: "30px",
                        }}
                    >
                        <span style={{
                            width: '26px',
                            height: '26px',
                            lineHeight: '25px',
                            borderRadius: '50%',
                            border: '1px solid #fff',
                            fontSize: '14px',
                            padding: '0px 5px',
                        }}>{this.currentLanguage.displayName==='简体中文'?'中':'英'}</span>
                        
                        {/* <Icon
                            type="global"
                            className={classNames("dropDown", "className")}
                            title={L("Languages")}
                        /> */}
                    </div>
                </Dropdown>
            </div>
        );
    }
}

export default withModel(useStore, (info) => info)(LanguageSelect);
