import { createModel } from 'hox';
import React from 'react'

const CheckPlan = () => {


    const [reload, setReload] = React.useState(false);

    return {
        reload, setReload
    };
}

export default createModel(CheckPlan)