import { sxAjax } from '@/commons/ajax';
declare var abp: any;
export default{
    namespace: 'archiveBook',
    initialState:{
        VolumeNumberVisible:false,
        VolumeNumber:[],//册号
    },
    setVolumeNumberVisible: (VolumeNumberVisible, _) => {
        return { VolumeNumberVisible };
    },
    BindingNewVolume: {//装订新册中
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesB/BindingNewVolume', params.BindingNewVolume);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    BindingExistenceBook: {//装订已有册
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesB/BindingExistenceBook', params.BindingExistenceBook);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    GetVoNumber: {
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesB/GetVoNumber', params.searchFillInData);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({VolumeNumber:payload.result});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
}