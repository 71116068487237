import { useState } from 'react';
import { createModel } from 'hox';

function usePackFiling() {
    //立卷规则
    const [bindingRules, setBindingRules] = useState([]);
    //档案类型
    const [category, setCategory] = useState({
        categoryId: '',
        categoryName: '',
        documentTypeId: '',
        formId: ''
    });
    //tabs切换key
    const [activeKey, setActiveKey] = useState(1);

    const [config, setConfig] = useState({
        fields: [],
        filter: []
    });
    const init = {
        filters: [],
        pager: {
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0
        }
    }
    //查询条件
    const [queryParams, setQueryParams] = useState({ ...init });
    //选中行数据
    const [reData, setReData] = useState({
        selectedRowKeys: [],
        selectedData: []
    });
    //重置选中行数据
    const reset = () => {
        setReData({
            selectedRowKeys: [],
            selectedData: []
        })
    }
    //pagelist查询
    const [fieldReload, setFieldReload] = useState(false);
    const [dataReload, setDataReload] = useState(false);
    //扫描窗口
    const [scanner, setScanner] = useState(false);
    return {
        bindingRules,
        reData,
        queryParams,
        category,
        scanner,
        config,
        activeKey,
        fieldReload,
        dataReload,
        setFieldReload,
        setDataReload,
        setActiveKey,
        setConfig,
        setScanner,
        setBindingRules,
        setCategory,
        setReData,
        setQueryParams,
        reset
    };
}

export default createModel(usePackFiling);