import { sxAjax } from '@/commons/ajax';
import { message } from 'antd';
import moment from "moment";
declare var abp: any;

export default {
    namespace: 'returnReg',
    initialState: {
        InitialDisplay: true,//默认显示空
        searchList: [],//列表设计-搜索选中标签
        searchTotalList: [],
        TableAllColumn: [],//列表所有字段,
        Showstable: [],//列表设计-表格选中标签,
        imageListData: [],//列表表格数据源
        imageListDataCount: 0,//列表数据总数
        searchFillInData: {//搜索条件值
            // FormId: '',
            // Filters: [
            // ]
            applicationNo: "",
            borrowDate: [],
            applicationDate: [],
            revertDate: [],
            PreReturnDate:[],
            borrower: "",
            borrowStatus: "未归还",
            pager: {
            pageIndex: 1,
            pageSize: 10
            },
            isReturn: true,
            type:4
        },
        searchExFillInData: {//搜索条件值
            applicationNo: "",
            borrowDate: [],
            applicationDate: [],
            revertDate: [],
            PreReturnDate:[],
            borrower: "",
            borrowStatus: "未出库",
            pager: {
            pageIndex: 1,
            pageSize: 10
            },
            isReturn: false,
            type:4
        },
        tabkey:1,
        selectedRows: [],//表格选中行数据
        selectedRowkeys: [],//表格选中行数据
        Borrower: "",
        BorrowStatus:'未出库',
        BorrowExStatus:'未归还',//出库状态
        StatusField: "",//借阅类型
        ApplicantNoField: "",//申请单号
        ApplicantNo: "",//申请单号
        revertDate: [],//归还日期搜索
        PreReturnDate:[],//预计归还日期搜索
        BorrowerField: "",//借阅人
        ReturnStatusField: '',// 归还状态
        ReturnDateField: '',//归还日期
        OverdueField: '',//是否超期
        OverdueDaysField: '',//超期天数
        PrincipalField: '',//负责人
        RemarkField: '',//备注
        ExpReturnDateField: '',//预计归还日期
        AppStatusField: '',//审核状态
        BorrowerDateField: '',//借阅日期
        SourcesData: '',//数据来源字段名
        FileTypeField: '',//档案类型字段名
        formId: '',
        MstId: '',
        FileType: '',//档案类型
        fields: [],//表单所有数据库字段
        ModileData: [],
        BatchOVisible: false,//批量操作弹窗隐藏显示
        CreateModVisible: false,//创建操作弹窗隐藏显示
        UpdateModVisible: false,//修改操作弹窗隐藏显示
    },

     /**组件卸载 */
     setUnmount: () => {
        return {
    
                InitialDisplay: true,//默认显示空
                searchList: [],//列表设计-搜索选中标签
                searchTotalList: [],
                TableAllColumn: [],//列表所有字段,
                Showstable: [],//列表设计-表格选中标签,
                imageListData: [],//列表表格数据源
                imageListDataCount: 0,//列表数据总数
                searchFillInData: {//搜索条件值
                    // FormId: '',
                    // Filters: [
                    // ]
                    applicationNo: "",
                    borrowDate: [],
                    applicationDate: [],
                    revertDate: [],
                    PreReturnDate:[],
                    borrower: "",
                    borrowStatus: "未归还",
                    pager: {
                    pageIndex: 1,
                    pageSize: 10
                    },
                    isReturn: true,
                    type:4
                },
                searchExFillInData: {//搜索条件值
                    applicationNo: "",
                    borrowDate: [],
                    applicationDate: [],
                    revertDate: [],
                    PreReturnDate:[],
                    borrower: "",
                    borrowStatus: "未出库",
                    pager: {
                    pageIndex: 1,
                    pageSize: 10
                    },
                    isReturn: false,
                    type:4
                },
                tabkey:1,
                selectedRows: [],//表格选中行数据
                selectedRowkeys: [],//表格选中行数据
                Borrower: "",
                BorrowStatus:'未出库',//出库状态
                BorrowExStatus:'未归还',//出库状态
                StatusField: "",//借阅类型
                ApplicantNoField: "",//申请单号
                ApplicantNo: "",//申请单号
                revertDate: [],//归还日期搜索
                PreReturnDate:[],//预计归还日期搜索
                BorrowerField: "",//借阅人
                ReturnStatusField: '',// 归还状态
                ReturnDateField: '',//归还日期
                OverdueField: '',//是否超期
                OverdueDaysField: '',//超期天数
                PrincipalField: '',//负责人
                RemarkField: '',//备注
                ExpReturnDateField: '',//预计归还日期
                AppStatusField: '',//审核状态
                BorrowerDateField: '',//借阅日期
                SourcesData: '',//数据来源字段名
                FileTypeField: '',//档案类型字段名
                formId: '',
                MstId: '',
                FileType: '',//档案类型
                fields: [],//表单所有数据库字段
                ModileData: [],
                BatchOVisible: false,//批量操作弹窗隐藏显示
                CreateModVisible: false,//创建操作弹窗隐藏显示
                UpdateModVisible: false,//修改操作弹窗隐藏显示
            }
    },
    setBorrower: (Borrower, _) => {
        return { Borrower };
    },
    setBorrowerField: (BorrowerField, _) => {
        return { BorrowerField };
    },
    setApplicantNoField: (ApplicantNoField, _) => {
        return { ApplicantNoField };
    },
    setApplicantNo: (ApplicantNo, _) => {
        return { ApplicantNo };
    },
    setRevertDate: (revertDate, _) => {
        return { revertDate };
    },
    setPreReturnDate: (PreReturnDate, _) => {
        return { PreReturnDate };
    },
    setBorrowStatus: (BorrowStatus, _) => {
        return { BorrowStatus };
    },
    setBorrowExStatus: (BorrowExStatus, _) => {
        return { BorrowExStatus };
    },
    setBatchOVisible: (BatchOVisible, _) => {
        return { BatchOVisible };
    },
    setCreateModVisible: (CreateModVisible, _) => {
        return { CreateModVisible };
    },
    setUpdateModVisible: (UpdateModVisible, _) => {
        return { UpdateModVisible };
    },
    setsearchFillInData: (searchFillInData, _) => {
        return { searchFillInData };
    },
    setsearchExFillInData: (searchExFillInData, _) => {
        return { searchExFillInData };
    },
    setTabkey:(tabkey, _)=>{
        return { tabkey };
    },
    setInitialDisplay: (InitialDisplay, _) => {
        return { InitialDisplay };
    },
    setSelectedRows: (selectedRows, _) => {
        return { selectedRows };
    },
    setSelectedRowKeys: (selectedRowkeys, _) => {
        return { selectedRowkeys };
    },
    setMstId: (MstId, _) => {
        return { MstId };
    },
    setFileType: (FileType, _) => {
        return { FileType };
    },
    getForm: {
        payload: (params) => { 
            return sxAjax.get('api/services/app/AppObject/GetForm?formId=' + params.formId);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { formId, formName, formType, data, properties, fields, listDesignAttrs } = payload.result;
                let Showstable = [];
                let TableAllColumn = [];
                let StatusField = payload.result.fields.find(function (value) {//借阅类型
                    return value.customType === "BorrowType"
                });

                let ExpReturnDateField = payload.result.fields.find(function (value) {//预计归还日期
                    return value.customType === "ExpReturnDate"
                });
                let BorrowerField = payload.result.fields.find(function (value) {//借阅人
                    return value.customType === "Borrower"
                });
                let ReturnStatusField = payload.result.fields.find(function (value) {//归还状态
                    return value.customType === "borrowStatus"
                });
                let BorrowerDateField = payload.result.fields.find(function (value) {//归还日期
                    return value.customType === "BorrowerDate"
                });
                let ReturnDateField = payload.result.fields.find(function (value) {//归还日期
                    return value.customType === "ReturnDate"
                });
                let OverdueField = payload.result.fields.find(function (value) {//是否超期
                    return value.customType === "Overdue"
                });
                let OverdueDaysField = payload.result.fields.find(function (value) {//超期天数
                    return value.customType === "OverdueDays"
                });
                let PrincipalField = payload.result.fields.find(function (value) {//负责人
                    return value.customType === "Principal"
                });
                let RemarkField = payload.result.fields.find(function (value) {//备注
                    return value.customType === "Remark"
                });
                let AppStatusField = payload.result.fields.find(function (value) {//审核状态
                    return value.customType === "ApprovalStatus"
                });
                let ApplicantNoField = payload.result.fields.find(function (value) {//申请单号
                    return value.customType === "ApplicantNo"
                });
                if (listDesignAttrs != null) {
                    if (listDesignAttrs.tableChecks != null) {
                        for (let i = 0; i < listDesignAttrs.tableChecks.length; i++) {
                            listDesignAttrs.tableChecks[i].dataIndex = listDesignAttrs.tableChecks[i].dataIndex.replace('-', '_');
                            listDesignAttrs.tableChecks[i].key = listDesignAttrs.tableChecks[i].key.replace('-', '_');
                            if (listDesignAttrs.tableChecks[i].checkeds == true && listDesignAttrs.tableChecks[i].key != AppStatusField?.id.replace('-', '_')) {
                                Showstable.push(listDesignAttrs.tableChecks[i])
                                TableAllColumn.push(listDesignAttrs.tableChecks[i])
                            } else {
                                TableAllColumn.push(listDesignAttrs.tableChecks[i])
                            }
                        }
                    }
                    if (listDesignAttrs.searchList != null) {
                        for (let i = 0; i < listDesignAttrs.searchList.length; i++) {
                            listDesignAttrs.searchList[i].value = listDesignAttrs.searchList[i].value.replace('-', '_')
                        }
                    }
                }

                let result = {
                    Showstable, TableAllColumn, BorrowerField: BorrowerField?.id, formId, fields,
                    ReturnStatusField: ReturnStatusField?.id, ReturnDateField: ReturnDateField?.id,
                    OverdueField: OverdueField?.id, OverdueDaysField: OverdueDaysField?.id,
                    PrincipalField: PrincipalField?.id, RemarkField: RemarkField?.id,
                    ExpReturnDateField: ExpReturnDateField?.id, AppStatusField: AppStatusField?.id,
                    ApplicantNoField: ApplicantNoField?.id, StatusField: StatusField?.id,
                    BorrowerDateField: BorrowerDateField?.id,
                    searchList: listDesignAttrs != null ? (listDesignAttrs.searchLists != null ? listDesignAttrs.searchLists : []) : [],
                };
                if (properties) {
                    result.properties = properties;
                }
                return (result);
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getListData: {//获取列表数据
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesService/TableListData', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let date = Date.parse(new Date(moment().format('YYYY-MM-DD')));
                let imageListData = payload.result;
                const imageListDataCount = payload.result.length;
                for (let i = 0; i < imageListData.length; i++) {
                    let Newdate = Date.parse(new Date(imageListData[i][state.ExpReturnDateField]))
                    let ReturnDate = imageListData[i][state.ReturnDateField]

                    let ss = ReturnDate == '' ? date : Date.parse(new Date(ReturnDate))
                    if (ss <= Newdate) {
                        imageListData[i][state.OverdueField] = '否' //是否超期
                        imageListData[i][state.OverdueDaysField] = ''  //超期天数
                    } else {
                        let day = parseInt((ss - Newdate) / (1000 * 60 * 60 * 24));
                        imageListData[i][state.OverdueField] = '是'
                        imageListData[i][state.OverdueDaysField] = day
                    }
                }

                return ({ imageListData: imageListData, imageListDataCount });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    SaveFormDataBatch: {
        payload: (params) => {
            return sxAjax.post('api/services/app/AppObject/SaveFormDataBatch', params.AppFormDataDto);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    GetApplicationPageList: {
        payload: (params) => {
            return sxAjax.post('api/services/app/EaaApplicationProcess/GetApplicationPageList', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                let ApplicationData = payload.result;
                const ApplicationDataCount = payload.result.length;
                return ({ ApplicationData: ApplicationData, ApplicationDataCount });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    GetAnnexList: {
        payload: (params) => {
            return sxAjax.get('api/services/app/EaaApplicationProcess/GetAnnexList?taskMstId='+params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                let AnnexData = payload.result;
                return ({ AnnexData: AnnexData });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    DealApplicationStatus: {
        payload: (params) => {
            return sxAjax.post('/api/services/app/EaaApplicationProcess/DealApplicationStatus', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                if(payload.success){
                    return message.success('成功');
                }else{
                    return message.error(payload.message);
                }
                
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },

  


  
getBorrowTotalListData: {//获取列表数据 借阅统计
    payload: (params) => {
        return sxAjax.post('/api/services/app/BorrowQuery/TotalTableListData', params);

    },
    reducer: {
        pending: (state, action) => ({ loading: true }),
        resolve(state, { payload = {} }) {
            let imageListData = payload.result;
                const imageListDataCount = payload.result.length;

                return ({ imageListData: imageListData, imageListDataCount });
        },
        complete: (state, action) => {
            return ({ loading: false });
        },
    }
},


UpdateAppDetailStatus: {//获取列表数据 借阅统计
    payload: (params) => { 
        return sxAjax.post('/api/services/app/BorrowQuery/UpdateAppDetailStatus', params);

    },
    reducer: {
        pending: (state, action) => ({ loading: true }),
        resolve(state, { payload = {} }) {
            return ({});
        },
        complete: (state, action) => {
            return ({ loading: false });
        },
    }
},
RenewArhives:{
    payload:(params)=>{
        return sxAjax.post('/api/services/app/EaaApplicationProcess/RenewArchives', params);
    },
    reducer:{
        pending: (state, action) => ({ loading: true }),
        resolve(state, { payload = {} }) {
             if(payload.success){
                    return message.success('成功');
                }else{
                    return message.error(payload.message);
                }
        },
        complete: (state, action) => {
            return ({ loading: false });
        },
    }
}


}