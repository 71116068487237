import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
import axios from 'axios';
import { getLoginUser } from '@/commons';
declare var abp: any;
export default {
    namespace: 'acquisition',
    initialState: {
        collapsed: false, //展开
        formTree: [
            {
                key: '',//最顶层流程
                title: '',//最顶层流程
                children: [{
                    key: '',//二级流程
                    title: '',//二级流程
                    documentNoField: '',//单据号字段名
                    batchNumberField: '',//批次号字段名
                    nextProcessFormID: '',//关联子流程ID
                    nextProcessFormName: '',//关联子流程名称
                    filteredOptions: [//文档标签下拉框
                        { value: 1, text: '票夹' },
                        { value: 22, text: '发票' },
                    ],
                }],
            }
        ],
        formTreeList: [],//树状图搜索使用的整理数据
        filteredOptions: [],//文档标签下拉框
        nextProcessFormID: '',//关联子流程ID
        nextProcessFormName: '',//关联子流程名称
        documentNo: '',//单据号字段名
        batchNumber: '',//批次号字段名
        documentView: '',//影像字段名
        documentStatus: '',//票据状态字段名
        formID: '',//搜索条件业务类型
        fileName: '',//搜索条件文件名称
        fileLabel: '',//搜索条件文档标签
        creatTime: '',//搜索条件创建时间
        MaxResultCount: 10,//当前每页展示行数
        SkipCount: 0,//跳过行数
        imagetotalCount: 0,//影像数据查询总数
        imageData: [],//表格数据源
        startOrDetail: true,//true显示主页面，false显示明细页面
        ReturnDisplay: 'none',//返回按钮隐藏显示属性
    },
    setCollapsed: (collapsed, _) => {
        return { collapsed };
    },
    setData: (o, state) => {
        let { data } = state;
        data = { ...data, ...o };
        return { data };
    },
    setValues: (value, state) => {
        let { values } = state;
        values = { ...values, ...value };
        return { values };
    },
    setCache: (data, state) => {
        let { cacheData } = state;
        cacheData = { ...cacheData, ...data };
        return { cacheData };
    },
    setDataLink: (data, state) => {
        let { dataLink } = state;
        dataLink = { ...dataLink, ...data };
        return { dataLink };
    },
    setStartOrDetail: (startOrDetail, _) => {
        return { startOrDetail };
    },
    setReturnDisplay: (ReturnDisplay, _) => {
        return { ReturnDisplay };
    },
    /**
     * 设置表单信息加载完成可以渲染了
     */
    setLoadComplete: (_, _state) => {
        return { loadComplete: true };
    },
    setTask: (task, _state) => {
        return { task }
    },
    showLoading: (_, _state) => {
        return { loading: true };
    },
    hideLoading: (_, _state) => {
        return { loading: false };
    },
    onblurFileName: (fileName) => {//文件名称变量值更新
        return { fileName };
    },
    onChangefileLabel: (fileLabel) => {//文档标签变量值更新
        return { fileLabel };
    },
    onChangeCreatTime: (creatTime) => {//创建时间变量值更新
        return { creatTime };
    },
    scanTreeData: {//树状图数据获取
        payload: (params) => {
            return sxAjax.get('api/services/app/AppObject/GetDocumentForm');
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let formTree = [];
                for (let i = 0; i < payload.result.length; i++) {
                    let formT = formTree.find(o => o.key === payload.result[i].appId);
                    let documentNoField = payload.result[i].fields.find(function (value) {//获取单据号字段名
                        return value.type === "documentNo"
                    });
                    let batchNumberField = payload.result[i].fields.find(function (value) {//获取批次号字段名
                        return value.type === "batchNumber"
                    });
                    let documentType = payload.result[i].fields.find(function (value) {//获取标签数据
                        return value.type === "documentType"
                    });
                    let documentView = payload.result[i].fields.find(function (value) {//获取标签数据
                        return value.type === "documentView"
                    });
                    let documentStatus = payload.result[i].fields.find(function (value) {//获取票据状态字段名
                        return value.type === "documentStatus"
                    });
                    let documentTypes = [];//标签数组定义
                    if (documentType != undefined) {
                        for (let j = 0; j < documentType.sourceData.length; j++) {
                            documentTypes.push({
                                value: documentType.sourceData[j].value,
                                text: documentType.sourceData[j].label,
                            })
                        }
                    }
                    if (formT == undefined) {
                        formTree.push({
                            key: payload.result[i].appId,//最顶层流程
                            title: payload.result[i].appName,//最顶层流程
                            children: [{
                                key: payload.result[i].formId,//二级流程
                                title: payload.result[i].formName,//二级流程
                                documentNoField: documentNoField != undefined ? documentNoField.id : '',//单据号字段名
                                batchNumberField: batchNumberField != undefined ? batchNumberField.id : '',//批次号字段名
                                documentStatus: documentStatus != undefined ? documentStatus.id : '',//票据状态字段名
                                documentView: documentView != undefined ? documentView.id : '1',//影像
                                nextProcessFormID: payload.result[i].nextProcess != null ? payload.result[i].nextProcess.formId : '',//关联子流程ID
                                nextProcessFormName: payload.result[i].nextProcess != null ? payload.result[i].nextProcess.formName : '',//关联子流程名称
                                filteredOptions: documentTypes//文档标签下拉框
                            }]
                        })
                    } else {
                        formT.children.push({
                            key: payload.result[i].formId,//二级流程
                            title: payload.result[i].formName,//二级流程
                            documentNoField: documentNoField != undefined ? documentNoField.id : '',//单据号字段名
                            batchNumberField: batchNumberField != undefined ? batchNumberField.id : '',//批次号字段名
                            documentStatus: documentStatus != undefined ? documentStatus.id : '',//票据状态字段名
                            documentView: documentView != undefined ? documentView.id : '1',//影像
                            nextProcessFormID: payload.result[i].nextProcess != null ? payload.result[i].nextProcess.formId : '',//关联子流程ID
                            nextProcessFormName: payload.result[i].nextProcess != null ? payload.result[i].nextProcess.formName : '',//关联子流程名称
                            filteredOptions: documentTypes//文档标签下拉框
                        })
                    }
                }
                return ({ formTree });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    searchScanclient: {//扫描Web端查询方法
        payload: (params) => {
            return sxAjax.get('api/services/app/AppForm/GetDocumentDataList', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imagetotalCount = payload.result.totalCount;//总行数
                for (let i = 0; i < payload.result.items.length; i++) {
                    let documentLabels = "";
                    for (let j = 0; j < payload.result.items[i].documentLabel.length; j++) {
                        documentLabels += payload.result.items[i].documentLabel[j] + "|"
                    }
                    payload.result.items[i].documentLabel = documentLabels.substr(0, documentLabels.length - 1);
                }
                let imageData = payload.result.items;//表格数据
                return ({ imagetotalCount, imageData });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }

    },
    scanMerge: {//扫描Web端合并方法
        payload: (params) => {
            return sxAjax.post('api/services/app/AppImageForm/ImageDocumentMerge', params.selectData);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    scanSubmit: {//扫描Web端提交方法
        payload: (params) => {
            return sxAjax.post('api/services/app/AppImageForm/ImageDocumentSubmit', params.selectData);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    scanDelete: {//扫描Web端删除方法
        payload: (params) => {
            return sxAjax.post('api/services/app/AppImageForm/ImageDocumentDelete', params.selectData);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let imagetotalCount = payload.result.totalCount;//总行数
                return ({ imagetotalCount });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    SetChangeData: (key, state) => {//标签数据源赋值
        let formT;
        for (let i = 0; i < state.formTree.length; i++) {
            if (formT == undefined) {
                formT = state.formTree[i].children.find(o => o.key == key);
            } else {
                break;
            }
        }
        //let formT = state.formTree[0].children.find(o => o.key == key);
        if (formT == undefined) {
            return ({
                filteredOptions: [],
                formID: '',
                nextProcessFormID: '',//关联子流程ID
                nextProcessFormName: '',//关联子流程名称
                documentNo: '',//单据号字段名
                batchNumber: '',//批次号字段名
                documentView: '',//影像字段名
                documentStatus: '',
            })
        } else {
            return ({
                filteredOptions: formT.filteredOptions,//标签
                formID: formT.key,
                nextProcessFormID: formT.nextProcessFormID,//关联子流程ID
                nextProcessFormName: formT.nextProcessFormName,//关联子流程名称
                documentNo: formT.documentNoField,//单据号字段名
                batchNumber: formT.batchNumberField,//批次号字段名
                documentView: formT.documentView,//影像字段名
                documentStatus: formT.documentStatus//票据状态
            })
        }
    },
    ImageDocListExport: {//导出方法
        payload: (params) => {
            return sxAjax.post('/api/services/app/AppImageDocListExcelExporter/ImageDocListExportToFile', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let url = abp.setting.get("App.Api.Default.Host") + "File/downloadTempFile?" + stringify(payload.result);
                window.open(url);
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    ImageDocListDownload: {//下载影像方法
        payload: (params) => {
            return sxAjax.post(abp.setting.get("App.Api.FTP.Host") + 'api/FTP/Down', params, { responseType: 'blob' });
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var blob = new Blob([payload], { type: 'application/octet-stream' })
                var downloadElement = document.createElement('a');
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download = '影像文件压缩包.zip'; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象 
            },
            complete: (state, action) => {
                return ({ loading: false })
            },
        }
    }
}