import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import * as storage from '@/library/utils/storage';
import * as sxRedux from '@/library/redux';
import App from './App';
import handleSuccess from './commons/handle-success';
import handleError from './commons/handle-error';
import {configureStore} from './models';
import * as serviceWorker from './serviceWorker';
import {getLoginUser} from './commons';
import './index.less';
import Utils from './commons/util';
import * as moment from 'moment';
import abpUserConfigurationService from './services/abpUserConfigurationService';



declare var abp: any;

// dev 模式开启mock
if (process.env.NODE_ENV === 'development' || process.env.MOCK === 'true') {
    require('./mock/index');
    console.log('current mode is development, mock is enabled');
}

const currentUser = getLoginUser() || {};

// 存储初始化 区分不同用户存储的数据
storage.init({
    keyPrefix: currentUser.id,
});

sxRedux.init({storage, handleError, handleSuccess});


Utils.setLocalization();

abpUserConfigurationService.getAll().then(data => {
    abp.appPath= 'http://localhost:62632/';
    // 生产机 模式
    if (process.env.NODE_ENV === 'production' && window._env) {
        abp.appPath = window._env.baseURL;
    }

    Utils.extend(true, abp, data.result);
    abp.clock.provider = Utils.getCurrentClockProvider(data.result.clock.provider);

    moment.locale(abp.localization.currentLanguage.name);

    if (abp.clock.provider.supportsMultipleTimezone) {
        moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
    }

    // models store
    const store = configureStore();
    ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));
  
    serviceWorker.unregister();

    console.log('test',store);
  });
