import { session } from '@/library/utils/storage';
import { getNodeByPropertyAndValue, convertToTree } from '@/library/utils/tree-utils';
import pathToRegexp from 'path-to-regexp/index';
import { ROUTE_BASE_NAME } from '@/router/AppRouter';
declare var abp: any;
var CryptoJS = require("crypto-js");


const CURRENT_USER_KEY = 'current-user';
export const authority = {
    allAuthorityName: "precise_all_permission",
    grantedAuthorityName: "precise_granted_permission"
}
const localStorage = window.localStorage;
const sessionStorage = window.sessionStorage;

/**
 * 判断是否有权限
 * @param code
 */
export function hasPermission(code) {
    const loginUser = getLoginUser();
    return loginUser?.permissions?.includes(code);
}

/**
 * 设置当前用户信息
 */
export function setLoginUser(currentUser = {}) {
    // 将用户属性在这里展开，方便查看系统都用到了那些用户属性
    const { id, name, avatar, token, permissions, roles } = currentUser;
    const userStr = JSON.stringify({
        id,             // 用户id 必须
        name,           // 用户名 必须
        avatar,         // 用头像 非必须
        token,          // 登录凭证 非必须 ajax请求有可能会用到，也许是cookie
        permissions,    //
        roles,//角色
    });

    localStorage.setItem(CURRENT_USER_KEY, userStr);
    sessionStorage.setItem(CURRENT_USER_KEY, userStr);
}
export function setAuthority(authorityName, authority) {
    const proAuthority = typeof authority === 'string' ? [authority] : authority;
    return localStorage.setItem(authorityName, JSON.stringify(proAuthority));
}
export function getAllAuthority(str) {
    const authorityString =
        typeof str === 'undefined' ? localStorage.getItem(authority.allAuthorityName) : str;
    let authority;
    try {
        authority = JSON.parse(authorityString);
    } catch (e) {
        authority = authorityString;
    }
    if (typeof authority === 'string') {
        return [authority];
    }
    return authority || [];
}
export function getGrantedAuthority(str) {
    const authorityString =
        typeof str === 'undefined' ? localStorage.getItem(authority.grantedAuthorityName) : str;
    let authority;
    try {
        authority = JSON.parse(authorityString);
    } catch (e) {
        authority = authorityString;
    }
    if (typeof authority === 'string') {
        return [authority];
    }
    return authority || [];
}

/**
 * 获取当前用户信息
 */
export function getLoginUser() {
    const loginUser = localStorage.getItem(CURRENT_USER_KEY);
    return loginUser ? JSON.parse(loginUser) : null;
}

/**
 * 判断用户是否登录
 */
export function isLogin() {
    // 如果当前用户存在，就认为已经登录了
    return !!getLoginUser();
}

/**
 * 进入首页
 */
export function toHome() {
    // 跳转页面，优先跳转上次登出页面
    //const lastHref = '/flowcenter/todo/'+abp.setting.get("App.TenantManagement.FlowCenterId");//window.sessionStorage.getItem('last-href');
    const lastHref = abp.setting.get("App.General.HomeUrl");
    // 强制跳转 进入系统之后，需要一些初始化工作，需要所有的js重新加载
    window.location.href = lastHref || `${ROUTE_BASE_NAME}/`;
}

/**
 * 进入后台管理界面
 */
export function toAdmin() {
    var adminUrl = abp.setting.get("App.Site.Admin.Url");
    window.open(adminUrl);
}

/**
 * 跳转到登录页面
 */
export function toLogin() {
    const loginPath = '/login';

    // 判断当前页面是否已经是login页面，如果是，直接返回，不进行跳转，防止出现跳转死循环
    const pathname = window.location.pathname;
    const isLogin = pathname.indexOf(loginPath) !== -1;

    if (isLogin) return null;

    // 清除相关数据
    session.clear();
    localStorage.setItem(CURRENT_USER_KEY, null);
    sessionStorage.clear();
    sessionStorage.setItem('last-href', window.location.pathname);

    // 强制跳转，让浏览器刷新，重置数据
    window.location.href = `${ROUTE_BASE_NAME}${loginPath}`;

    return null;
}

/**
 * 根据path获取对应的菜单
 * @param path
 * @param menuTreeData
 * @returns {*}
 */
export function getSelectedMenuByPath(path, menuTreeData) {
    path = path.replace(ROUTE_BASE_NAME, '');
    let selectedMenu;
    if (menuTreeData) {
        if (path.indexOf('/_') > -1) {
            path = path.substring(0, path.indexOf('/_'));
        }

        // 先精确匹配
        selectedMenu = getNodeByPropertyAndValue(menuTreeData, 'path', path, (itemValue, value, item) => {
            const isTop = item.children && item.children.length;
            return itemValue === value && !isTop; // 排除父级节点
        });

        // 正则匹配，路由中有`:id`的情况
        // fixme 容易出问题：a/b/:id,会匹配 a/b/1, a/b/detail，有可能不是期望的结果，注意路由写法
        // fixme: a/b/tab/:id 具体的:id，添加一级，用来表明id是什么
        if (!selectedMenu && path !== '/') {
            selectedMenu = getNodeByPropertyAndValue(menuTreeData, 'path', path, (itemValue, value, item) => {
                const isTop = item.children && item.children.length;
                const re = pathToRegexp(itemValue);
                return !!re.exec(value) && !isTop; // 排除父级节点
            });
        }
    }
    return selectedMenu;
}


/**
 * 获取菜单树状结构数据 和 随菜单携带过来的权限
 * @param menus 扁平化菜单数据
 */
export function getMenuTreeDataAndPermissions(menus) {
    // 用户权限code，通过菜单携带过来的 1 => 菜单 2 => 功能
    const permissions = menus.map(item => {
        if (item.type === '1') return item.key;
        if (item.type === '2') return item.code;
        return null;
    });

    // 获取菜单，过滤掉功能码
    menus = menus.filter(item => item.type !== '2');

    // 处理path： 只声明了url，为iframe页面
    menus = menus.map(item => {
        if (item.url) {
            item.path = `/iframe_page_/${window.encodeURIComponent(item.url)}`;
        }
        return item;
    });

    // 菜单根据order 排序
    const orderedData = [...menus].sort((a, b) => {
        const aOrder = a.order || 0;
        const bOrder = b.order || 0;

        // 如果order都不存在，根据 text 排序
        if (!aOrder && !bOrder) {
            return a.text > b.text ? 1 : -1;
        }
        //修改菜单排序，aOrder-bOrder 从小到大，bOrder-aOrder 从大到小排序
        return aOrder - bOrder;
    });

    // 设置顶级节点path，有的顶级没有指定path，默认设置为子孙节点的第一个path
    const findPath = (node) => {
        const children = orderedData.filter(item => item.parentKey === node.key);
        let path = '';
        if (children && children.length) {
            for (let i = 0; i < 1; i++) {//children.length
                const child = children[i];
                if (child.path) {
                    path = child.path;
                    break;
                }
                path = findPath(child);
            }
        }
        return path;
    };

    orderedData.forEach(item => {
        if (!item.path) {
            item.path = findPath(item);
        }
    });

    const menuTreeData = convertToTree(orderedData);
    return { menuTreeData, permissions }
}

/***
 *
 * 密码规则校验
 *
 */
export function pasValidate(data, require) {
    const minnum = require.requiredLength;
    const rule1 = /\d+/;
    const rule2 = /[a-z]+/;
    const rule3 = /[A-Z]+/;
    const rule4 = /[~!@#\$%^&*\(\)\{\};,.\?\/'"]/;
    const rule5 = /^.{3,16}$/;
    const rule6 = new RegExp(`^.{${minnum},100}$`);
    const flag1 = rule1.test(data);
    const flag2 = rule2.test(data);
    const flag3 = rule3.test(data);
    const flag4 = rule4.test(data);
    const flag5 = rule5.test(data);
    const flag6 = rule6.test(data);
    var num = '';
    var valiStatus = false;
    if (require.requireDigit) {
        num = num + '数字,'
        if (!flag1) {
            valiStatus = true
        }
    }
    if (require.requireLowercase) {
        num = num + '小写字母,';
        if (!flag2) {
            valiStatus = true
        }
    }
    if (require.requireNonAlphanumeric) {
        num = num + '特殊字符,';
        if (!flag3) {
            valiStatus = true
        }
    }
    if (require.requireUppercase) {
        num = num + '大写字母,';
        if (!flag4) {
            valiStatus = true
        }
    }
    if (require.requiredLength) {
        num = num + `长度最小为${minnum},`
        if (!flag6) {
            valiStatus = true
        }
    }
    if (valiStatus) {
        return num.slice(0, num.length - 1);
    } else {
        return false
    }

}

export function encryption(params) {
    let {
        data,
        type,
        param,
    } = params;
    var key = "TKD3EPVg4DWsJ6L7";
    let result = JSON.parse(JSON.stringify(data));
    if (type == 'Base64') {
        param.forEach(ele => {
            result[ele] = btoa(result[ele]);
        })
    } else if (type == 'Aes') {
        param.forEach(ele => {
            let iv = key;
            key = CryptoJS.enc.Utf8.parse(key);
            iv = CryptoJS.enc.Utf8.parse(iv);
            result[ele] = CryptoJS.AES.encrypt(result[ele], key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
        })

    }
    return result;
}
