import {sxAjax} from '@/commons/ajax';

export default{
    namespace:'putaway',
    initialState:{
        fileCabinetList:[],
        companyData:[],
        warehouseData:{},
        selectedCabinet:{},//选择的树
        warehouseId:'',//仓库编号
    },
    onWarehouseNumber:(fileValue,state)=>{
        let { warehouseInfo } = state;
        warehouseInfo.warehouseNumber=fileValue
        return { warehouseInfo };
    },
    onSetFocusCabinet:(fileValue,state)=>{
        let {selectedCabinet}=state;
        selectedCabinet.selectedKey=fileValue?.key;
        selectedCabinet.children=fileValue?.props.children;
        return {selectedCabinet};
    },
    onSetWarehouseMstId:(warehouseValue,state)=>{
        let { warehouseId } = state;
        warehouseId=warehouseValue
        return { warehouseId };
    },
    getFileCabinetList:{
        payload:(params)=>sxAjax.get('api/services/app/FileCabinet/GetFileCabinetTreeList',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={fileCabinetList:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getCompany:{
        payload:(params)=>sxAjax.get('api/services/app/OrganizationUnit/GetCompanyList',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={companyData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getWarehoueInfo:{
        payload:(params)=>sxAjax.get('api/services/app/Warehouse/GetWarehouseInfo',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={warehouseData:payload.result};
                return(data);
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    createWarehouse:{
        payload:(params)=>sxAjax.post('api/services/app/Warehouse/AddWarehouse',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
}