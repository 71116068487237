import {sxAjax} from '@/commons/ajax';

export default{
    namespace: 'notifications',
    initialState:{
        notificationInfos:[],
        unreadCount:0,
        totalCount:0,
        loading:false,
        publishNotificationHistory:{
            totalCount:0,
            items:[]
        },
        systemNotificationHistory:{
            totalCount:0,
            items:[]
        },
        systemLogSetting:{},
        sysMsgData:{}
    },
    getNotificationList:{
        payload:(params)=>sxAjax.get('api/services/app/Notification/GetUserNotifications',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                const {totalCount,items=[],unreadCount}=payload.result;
                return {
                    notificationInfos: items,
                    unreadCount:unreadCount,
                    totalCount:totalCount
                };
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    publishNotification:{
        payload:(params)=>sxAjax.post('api/services/app/Notification/Publish_SentMessage',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
               return {};
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getPublishNotificationHistory:{
        payload:(params)=>sxAjax.get('api/services/app/Notification/GetPublishNotificationHistory',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                const {totalCount,items=[]}=payload.result;
                return {
                    publishNotificationHistory:{
                        items:items,
                        totalCount:totalCount
                    }
                };
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getSystemNotificationHistory:{
        payload:(params)=>sxAjax.get('api/services/app/Notification/GetSystemNotificationHistory',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                const {totalCount,items=[]}=payload.result;
                return {
                    systemNotificationHistory:{
                        items:items,
                        totalCount:totalCount
                    }
                };
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getSystemLogSetting:{
        payload:(params)=>sxAjax.get('api/services/app/Notification/GetSysLogSetting?type='+params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={systemLogSetting:payload.result};
                return(data);
            },
            complete:(state,action)=>({loading:false}),
        }
    },
    setSystemLogSetting:{
        payload:(params)=>sxAjax.post('/api/services/app/Notification/ChangeSysLogSetting',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    getSystemMsgSetting:{
        payload:(params)=>sxAjax.get('api/services/app/Notification/GetSystemNotification'),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                var data={sysMsgData:payload.result};
                return(data);
            },
            complete:(state,action)=>({loading:false}),
        }
    },
    setSystemMsgSetting:{
        payload:(params)=>sxAjax.post('/api/services/app/Notification/SettingSystemNotification',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    setNotificationAsRead:{
        payload:(params)=>sxAjax.post('/api/services/app/Notification/SetAnyNotificationAsRead',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    setAllNotificationAsRead:{
        payload:(params)=>sxAjax.post('/api/services/app/Notification/SetAllNotificationsAsRead',params),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    },
    setAllNotificationDelete:{
        payload:(params)=>sxAjax.post('/api/services/app/Notification/DeleteNotificationList',params.Id),
        reducer:{
            pending:(state,action)=>({loading:true}),
            resolve(state,{payload={}}){
                
            },
            complete:(state,action)=>{
                return ({loading:false})
            },
        }
    }
}