import { sxAjax } from "@/commons/ajax";

import * as moment from "moment";

declare var abp: any;
export default {
    namespace: "flow",
    initialState: {
        taskList: {
            //任务列表
            items: [],
            totalCount: 1,
        },
        processCategory: [],
        createTimeScope: [
            {
                key: "month",
                name: "最近一个月",
            },
            {
                key: "threeMonth",
                name: "最近三个月",
            },
            {
                key: "sexMonth",
                name: "最近六个月",
            },
            {
                key: "lastYear",
                name: "今年去年",
            },
        ],
        loading: true,
        loadingData: true,
        flowCenterId: abp.setting.get("App.TenantManagement.FlowCenterId"),
        flowMonitor: {},
        taskInfo: {},
        filterOption: {
            //搜索条件
            SkipCount: 0,
            MaxResultCount: 10,
            Summary: "",
            TaskUser: [],
            Status: 1,
            ProcessNames: [],
            startTime: moment().add(-1, "months").calendar(),
            endTime: moment().format("L"),
            Initiator: "",
            StepTypes: [],
        },
        batchOption: {
            status: "None",
            selectKeys: [],
        },
        batchProcessSteps: [],
        ccStatus:"1",
    },
    setUnmount:()=>{
        return {
        taskList: {//任务列表
            items: [],
            totalCount: 1,
        },
        processCategory: [],
        createTimeScope: [{
            key: "month",
            name: "最近一个月"
        },
        {
            key: "threeMonth",
            name: "最近三个月"
        }, {
            key: "sexMonth",
            name: "最近六个月"
        }, {
            key: "lastYear",
            name: "今年去年"
        }],
        flowMonitor:{},
        taskInfo:{},
        filterOption:{//搜索条件
            SkipCount: 0,
            MaxResultCount: 10,
            Summary: "",
            TaskUser: [],
            Status: 1,
            ProcessNames: [],
            startTime: moment().add(-1, 'months').calendar(),
            endTime: moment().format('L'),
            Initiator: "",
            StepTypes:[]
        },
        batchOption:{
            status:"None",
            selectKeys:[]
        },
        batchProcessSteps:[],
        ccStatus:"1"};
    },
    setNullTaskList:()=>{
        return {taskList: {//任务列表
            items: [],
            totalCount: 1,
        }};
    },
    setFilterOption: (option, state) => {
        let { filterOption } = state;
        return { filterOption: { ...filterOption, ...option } };
    },
    setBatchOptionStatus: (value, state) => {
        return {
            batchOption: {
                status: value,
                selectKeys: [],
            },
        };
    },
    setBatchOptionKeys: (value, state) => {
        let {
            batchOption: { status },
        } = state;
        return {
            batchOption: {
                status: status,
                selectKeys: value,
            },
        };
    },
    setCCStatus: (ccStatus, state) => {
        let { filterOption } = state;
        return { ccStatus };
    },
    getProcessCategory: {
        payload: (params) =>
            sxAjax.get(
                "api/services/app/NaviagationAppServices/GetProcessCategories",
                params
            ),
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {
                    processCategory: payload.result,
                };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getTaskList: {
        payload: (params) => {
            return sxAjax.post(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/GetTaskList",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loadingData: true }),
            resolve(state, { payload = {} }) {
                const { taskList } = state;
                return {
                    taskList: {
                        ...taskList,
                        ...{
                            items: payload.result.items,
                            totalCount: payload.result.totalCount,
                        },
                    },
                };
            },
            complete: (state, action) => {
                return { loadingData: false };
            },
        },
    },
    getTaskInfoByIncidentId: {
        payload: (params) => {
            return sxAjax.get(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Task/GetTaskInfoByIncidentId",
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {
                    taskInfo: payload.result,
                };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getIncidentMonitor: {
        payload: (params) => {
            return sxAjax.get(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Incident/GetMonitor?incidentId=" +
                    params,
                params
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {
                    flowMonitor: {
                        nodes: payload.result.flowNodes,
                        edges: payload.result.flowEdges,
                    },
                };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getBatchProcessSteps: {
        payload: () => {
            return sxAjax.get(
                abp.setting.get("App.Api.Workflow.Host") +
                    "api/services/app/Process/GetBatchProcessSteps"
            );
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return {
                    batchProcessSteps: payload.result,
                };
            },
            complete: (state, action) => {
                return { loading: false };
            },
        },
    },
    getProcessInfo: {
        payload: (params) => {
            return sxAjax.get(abp.setting.get("App.Api.Workflow.Host") + "api/services/app/Process/GetProcessInfo?processName=" + params.processName + "&version=" + params.version);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return {};
            },
            complete: (state, action) => {
                return {};
            },
        },
    },
};
