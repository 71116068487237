import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
import axios from 'axios';
declare var abp: any;
export default{
    namespace: 'archiveBook',
    initialState:{
        VolumeNumberVisible:false,
        VolumeNumber:[],//册号
        BasicConfigData:[],//基础配置
        MaxNumber:'',//当前已装册最大凭证编号
        CatalogueDsVisible:false,//侧明细弹窗
        MetedataData:[],//元数据
        BookFromData:[],
        fileType:'',//文件类型
    },
    setVolumeNumberVisible: (VolumeNumberVisible, _) => {
        return { VolumeNumberVisible };
    },
    setCatalogueDsVisible: (CatalogueDsVisible, _) => {
        return { CatalogueDsVisible };
    },
    setFileType: (fileType, _) => {
        return { fileType };
    },
    BindingNewVolume: {//装订新册中
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesB/BindingNewVolume', params.BindingNewVolume);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    BindingExistenceBook: {//装订已有册
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesB/BindingExistenceBook', params.BindingExistenceBook);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    GetVoNumber: {//获取所有册号
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesB/GetVoNumber', params.searchFillInData);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({VolumeNumber:payload.result});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    GetNumber: {//获取最大凭证编号
        payload: (params) => {
            return sxAjax.post('api/services/app/ArchivesB/GetNumber', params.searchFillInData);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({MaxNumber:payload.result});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getBasicConfig: {//获取基础配置
        payload: (params) => {
            return sxAjax.get('/api/services/app/BasicConfig/GetBasicConfigData',params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                let BasicConfigData = payload.result;
                return ({ BasicConfigData });
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    PackedVolumeProcessing:{//已装册状态更新
        payload: (params) => {
            return sxAjax.post('/api/services/app/ArchivesB/PackedVolumeProcessing',params.BindingExistenceBook);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                // const data = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getMetadataList: {//根据册表mstid获取元数据
        payload: (params) => {
            return sxAjax.get('api/services/app/ArchivesB/GetFileMetadataList', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({MetedataData:payload.result});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getVolumeWithinFile: {//根据册表mstid获取元数据
        payload: (params) => {
            return sxAjax.get('api/services/app/ArchivesB/GetVolumeWithinFile', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({MetedataData:payload.result});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    GetBookFormList: {
        payload: (params) => {
            return sxAjax.get('api/services/app/ArchivesB/GetBookFormData?BookFormID=' + params.Bookdata[0].BookFormID+'&MstId='+params.Bookdata[0].MstId);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({BookFromData:payload.result});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    }
}